import {
  Box,
  Button,
  MenuItem,
  PaginationItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";
import { NumericFormat } from "react-number-format";
import config from "../utils/config";

const PrevButton = () => {
  return <Button>Prev</Button>;
};

const NextButton = () => {
  return <Button>Next</Button>;
};

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack
      direction={isDesktop ? "row" : "column"}
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{ py: 2 }}
    >
      <Box>
        <Stack direction="row" alignItems="center">
          <Typography>Showing</Typography>
          <Select
            value={pageSize}
            onChange={(event: any) =>
              apiRef.current.setPageSize(event.target.value)
            }
            size="small"
            sx={{ mx: 1 }}
          >
            {config.DEFAULT_PAGE_SIZE?.map((item: any, i: any) => (
              <MenuItem value={item} key={i}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <Typography>
            of{" "}
            <NumericFormat
              value={apiRef.current.getRowsCount()}
              displayType="text"
              thousandSeparator
            />{" "}
            items
          </Typography>
        </Stack>
      </Box>

      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            components={{
              previous: PrevButton,
              next: NextButton,
            }}
          />
        )}
      />
    </Stack>
  );
}
