import CookieJs from "js-cookie";

interface Props {
  children: any;
  acceptPermissions: number[];
}

export default function ProtectedPermissionView(props: Props) {
  const { children, acceptPermissions } = props;
  const user = JSON.parse(CookieJs.get("USER") || "");
  const isAdmin = user.is_admin;

  const isHavePermission = acceptPermissions.indexOf(user.role_id) >= 0;
  if (!isHavePermission && !isAdmin) {
    return null;
  }

  return children;
}
