import { Box, Grid, Stack, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import voucher from "../services/voucher";
import { rupiah } from "../utils/currency";

export default function VoucherDetail() {
  const { id } = useParams();
  const [voucherReport, setVoucherReport] = useState([
    { username: "", count: 0 },
  ]);
  const chartSetting = {
    height: 300,
  };
  const [data, setData] = useState({
    code: "",
    quota: 0,
    price: 0,
    amount: 0,
    created_by: "",
    created_at: "",
  });

  useEffect(() => {
    voucher.getVoucherById(id).then((response: any) => {
      setData({
        code: response.data?.code,
        quota: response.data?.quota,
        amount: response.data?.amount,
        price: response.data?.price,
        created_at: response.data?.created_at,
        created_by: response.data?.created_by,
      });
      if (response.data?.voucher_stat?.length > 0) {
        for (const i of response.data.voucher_stat) {
          i.count = Number(i.count);
        }
        setVoucherReport(response.data?.voucher_stat);
      }
    });
  }, [id]);

  return (
    <MainLayout>
      <Helmet>
        <title>Voucher Detail</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Voucher", href: "/app/voucher" }]}
        label={data.code}
        href={"/app/voucher"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Code
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.code}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Created At
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {moment(data.created_at).format("D/MM/YYYY HH:mm")}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Token Amount
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.amount}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Price
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {rupiah(data.price)}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Quota
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.quota}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Voucher Graph</Typography>
          <BarChart
            dataset={voucherReport}
            xAxis={[{ scaleType: "band", dataKey: "username", label: "User" }]}
            series={[{ dataKey: "count", type: "bar" }]}
            {...chartSetting}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
