import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const createTopUp = async (params: any) => {
  const url = endpoints.TOPUP;

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const createQR = async (params: any) => {
  const url = endpoints.TOPUP + '/generateQR';

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getTopupStatus = async (params: any) => {
  const url = endpoints.TOPUP + '/status';

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params.data
  });

  return response;
}

const setExpired = async (params: any) => {
  const url = endpoints.TOPUP + '/expired';

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params.data
  });

  return response;
}

const getAllTopUpByUser = async (params: any) => {
  const url = endpoints.TOPUP;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params,
  });

  return response;
};

const flagTopup = async (params: any) => {
  const url = endpoints.FLAG_TOPUP;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const proceedTopup = async (params: any) => {
  const url = endpoints.PROCEED_TOPUP;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getAllTopUpByUser,
  createTopUp,
  createQR,
  getTopupStatus,
  flagTopup,
  proceedTopup,
  setExpired
};

export default service;
