import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import useNotification from "../hooks/useNotification";
import event from "../services/event";
import queryDefault from "../utils/queryDefault";
import role from "../services/role"
import userService from "../services/users"
import { useEffect } from "react";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";

export default function UserEdit() {
  const notification = useNotification();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    role_id: yup.string().required("role is a required field"),
    event_id: yup.string().required("event is a required field"),
  });
  const form = useForm({
    defaultValues: {
      id: "",
      username: "",
      role_id: "",
      admin_name: user.username,
      event_id: "",
    },
    resolver: yupResolver(schema),
  });

  const updateUser = useMutation("create_user", userService.editUser, {
    onSuccess: () => {
      navigate("/app/users/");

      notification.onOpen({
        message: "User was successfully updated!",
        type: "success",
        position: "top",
      });
    },
    onError: () => {
      notification.onOpen({
        message: "Failed Update User",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    updateUser.mutate({
      data: values,
    });
  };

  const listEvent = useQuery("list-event", () =>
    event.getAllEvent({ keyword: "" })
  );

  const listRole = useQuery("list-role", () =>
    role.getAllRole()
  );

  useEffect(() => {
    if (id) {
      userService.viewUser(id)
        .then(({ data }) => {
          form.setValue("id", id);
          form.setValue("role_id", data.role_id);
          form.setValue("username", data.username);
          form.setValue("event_id", data.event_id);
        })
    }
  }, [id])


  return (
    <MainLayout>
      <Breadcrumb
        label="Edit User"
        breadcrumbs={[{ label: "Users", href: "/app/users" }]}
        href={"/app/users"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Username</FormLabel>
                <Controller
                  name="username"
                  control={form.control}
                  disabled
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Event</FormLabel>
                <Controller
                  name="event_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(
                          listEvent,
                          ["data", "data"],
                          []
                        ).map((item: any, i: any) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Role</FormLabel>
                <Controller
                  name="role_id"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Select
                        {...field}
                        size="small"
                        error={Boolean(fieldState.error?.message)}
                        fullWidth
                      >
                        {get(
                          listRole,
                          ["data", "data"],
                          []
                        ).map((item: any, i: any) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={Boolean(fieldState.error?.message)}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={updateUser.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
