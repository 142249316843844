import { Box, Typography } from "@mui/material";

export default function AccessDenied(props: any) {
  const { small } = props;

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: small ? "calc(100vh - 250px)" : "calc(100vh - 80px)",
        flexDirection: "column",
      }}
    >
      <Box>
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.9375 21.7647C30.9375 20.7089 31.777 19.8529 32.8125 19.8529H60.9375C61.973 19.8529 62.8125 20.7089 62.8125 21.7647C62.8125 22.8205 61.973 23.6765 60.9375 23.6765H32.8125C31.777 23.6765 30.9375 22.8205 30.9375 21.7647ZM71.25 21.7647C71.25 20.7089 72.0895 19.8529 73.125 19.8529H76.875C77.9105 19.8529 78.75 20.7089 78.75 21.7647C78.75 22.8205 77.9105 23.6765 76.875 23.6765H73.125C72.0895 23.6765 71.25 22.8205 71.25 21.7647ZM82.5 21.7647C82.5 20.7089 83.3395 19.8529 84.375 19.8529H88.125C89.1605 19.8529 90 20.7089 90 21.7647C90 22.8205 89.1605 23.6765 88.125 23.6765H84.375C83.3395 23.6765 82.5 22.8205 82.5 21.7647ZM26.25 28.4559H93.75V18.8971C93.75 16.7854 92.0711 15.0735 90 15.0735H30C27.9289 15.0735 26.25 16.7854 26.25 18.8971V28.4559ZM22.5 80.0735C22.5 84.2969 25.8579 87.7206 30 87.7206H90C94.1421 87.7206 97.5 84.2969 97.5 80.0735V18.8971C97.5 14.6737 94.1421 11.25 90 11.25H30C25.8579 11.25 22.5 14.6737 22.5 18.8971V80.0735ZM93.75 32.2794V80.0735C93.75 82.1852 92.0711 83.897 90 83.897H30C27.9289 83.897 26.25 82.1852 26.25 80.0735V32.2794H93.75Z"
            fill="#4D4D4F"
          />
          <ellipse
            cx="61.875"
            cy="83.8982"
            rx="22.5"
            ry="22.9412"
            fill="white"
          />
          <path
            d="M61.875 63.1875C50.6422 63.1875 41.5625 72.4452 41.5625 83.8983C41.5625 95.3513 50.6422 104.609 61.875 104.609C73.1078 104.609 82.1875 95.3513 82.1875 83.8983C82.1875 72.4452 73.1078 63.1875 61.875 63.1875ZM70.6094 92.8039C69.8172 93.6116 68.5375 93.6116 67.7453 92.8039L61.875 86.8185L56.0047 92.8039C55.2125 93.6116 53.9328 93.6116 53.1406 92.8039C52.3484 91.9962 52.3484 90.6914 53.1406 89.8837L59.0109 83.8983L53.1406 77.9129C52.3484 77.1051 52.3484 75.8004 53.1406 74.9926C53.9328 74.1849 55.2125 74.1849 56.0047 74.9926L61.875 80.9781L67.7453 74.9926C68.5375 74.1849 69.8172 74.1849 70.6094 74.9926C71.4016 75.8004 71.4016 77.1051 70.6094 77.9129L64.7391 83.8983L70.6094 89.8837C71.3812 90.6707 71.3812 91.9962 70.6094 92.8039Z"
            fill="#4D4D4F"
          />
        </svg>
      </Box>

      <Typography fontSize="20px" fontWeight="600" sx={{ mt: 2 }}>
        ACCESS DENIED
      </Typography>

      <Typography fontSize="16" fontWeight="400" sx={{ mt: 2 }}>
        You don’t have permission to access this page
      </Typography>
    </Box>
  );
}
