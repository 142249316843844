import CookieJs from "js-cookie";
import AccessDenied from "../pages/AccessDenied";
import LoadingPage from "../pages/LoadingPage";

interface Props {
  children: any;
  small?: boolean;
  acceptPermissions: any;
}

export default function ProtectedPermissionPage(props: Props) {
  const { children, acceptPermissions, small } = props;
  const user = JSON.parse(CookieJs.get("USER") || "");
  const isAdmin = user.is_admin;
  const isHavePermission = acceptPermissions.indexOf(user.role_id) >= 0;

  if (!user) {
    return <LoadingPage />;
  }
  if (!isHavePermission && !isAdmin) {
    return <AccessDenied small={small} />;
  }

  return children;
}
