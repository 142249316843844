import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import event from "../services/event";
import queryDefault from "../utils/queryDefault";

export default function EventEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("event name is a required field"),
    token_price: yup.number().required("token price is a required field"),
  });
  const currentEvent = useQuery("view-event", () => event.viewEventDetail(id));
  const form = useForm({
    defaultValues: {
      name: "",
      token_price: "",
      username: user.username,
      topup_commission: "",
      transaction_commission: ""
    },
    resolver: yupResolver(schema),
  });

  const createEvent = useMutation("create_event", event.createEvent, {
    onSuccess: () => {
      navigate("/app/event/");

      notification.onOpen({
        message: "Event was successfully created!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateEvent = useMutation("update-event", event.updateEvent, {
    onSuccess: () => {
      navigate("/app/event/");

      notification.onOpen({
        message: "Event was successfully created!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    if (id) {
      updateEvent.mutate({
        data: {
          ...values,
          eventId: id,
        },
      });
    } else {
      createEvent.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("name", get(currentEvent, "data.data.name"));
      form.setValue("token_price", get(currentEvent, "data.data.token_price"));
      form.setValue("topup_commission", get(currentEvent, "data.data.topup_commission"));
      form.setValue("transaction_commission", get(currentEvent, "data.data.transaction_commission"));
    }
  }, [currentEvent, form, id]);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Event" : "Create Event"}
        breadcrumbs={[
          { label: "Event", href: "/app/event" },
          {
            label: id ? "Edit Event" : "Create Event",
            href: "/app/events/create-event",
          },
        ]}
        href={"/app/event"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        {!currentEvent.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth>
                  <FormLabel required>Event Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Token Price</FormLabel>
                  <Controller
                    name="token_price"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={id !== undefined}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel>Topup Commission</FormLabel>
                  <Controller
                    name="topup_commission"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={id !== undefined}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel>Transaction Commission Percentage</FormLabel>
                  <Controller
                    name="transaction_commission"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        disabled={id !== undefined}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createEvent.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
