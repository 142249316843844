import { Box } from "@mui/material";

export default function MainLayout(props: any) {
  const { children } = props

  return (
    <Box sx={{ px: 4, py: 4 }}>
      {children}
    </Box>
  )
}