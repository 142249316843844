import React, { useEffect, useState, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CookieJs from "js-cookie";
import { find } from "lodash";
import get from "lodash/get";
import { Controller, useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import topup from "../services/topup";
import service from "../services/voucher";
import wristband from "../services/wristband";
import { rupiah } from "../utils/currency";
import queryDefault from "../utils/queryDefault";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../components/Breadcrumb";
import moment from "moment";

export default function TopUpCreate() {
  let audio = new Audio("/beep.mp3");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const checkQrShown = () => {
    const qr = document.getElementById("html5qr-code-full-region");
    if (qr?.innerHTML === "") {
      window.location.reload();
    }
  };
  const isXendit = window.location.href.indexOf("xendit") >= 0;
  const navigate = useNavigate();

  setTimeout(checkQrShown, 500);
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [data, setData] = useState({
    wristband_id: 0,
    wristband_code: "",
    message: "Start Scanning!",
    error: "",
    balance: 0,
    event_id: user.event_id,
    token_price: 0,
    isSuccess: false,
    isError: false,
    topup_commission: 0,
    is_manual: !isXendit,
    qr_string: "",
    reference_id: "",
  });

  const [options] = useState<any>({
    keyword: "",
    username: user?.username,
    event_id: user?.event_id,
  });

  const form = useForm({
    defaultValues: {
      event_id: user.event_id,
      amount: 0,
      is_manual: !isXendit,
      wristband_code: "",
      voucher_id: 0,
      total_amount: 0,
      balance: 0,
      username: user.username,
      topup_commission: 0,
      reference_id: "",
    },
  });

  const listVoucher = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return service.getAvailableVoucher({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    audio.play();
    validateWristband(decodedText);
    document.getElementById("html5-qrcode-button-camera-stop")?.click();
  };

  const createTopup = useMutation("topup", topup.createTopUp, {
    onSuccess: () => {
      navigate("/app/topup");
      Swal.fire({
        title: `Success Topup`,
        html: `Success topup ${
          data.wristband_code
        } with amount ${form.getValues("amount")} token <br/>
        <strong>Total Token : ${
          data.balance + form.getValues("amount")
        }<strong>`,
        icon: "success",
      });
    },
    onError: () => {
      Swal.fire({
        title: `Error`,
        text: `Error Topup ${data.wristband_code} wristband`,
        icon: "error",
      });
    },
    ...queryDefault,
  });

  const expiredFunction = () => {
    topup
      .setExpired({
        data: {
          reference_id: form.getValues("reference_id"),
        },
      })
      .then(() => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
        Swal.fire({
          title: `Expired`,
          text: `Topup ${form.getValues("amount")} token on ${
            data.wristband_code
          } wristband expired!`,
          icon: "warning",
        });
      })
      .catch(() => {
        Swal.fire({
          title: `Error`,
          text: `Failed to set expired Topup ${form.getValues(
            "amount"
          )} token on ${data.wristband_code} wristband`,
          icon: "error",
        });
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const createQR = useMutation("topup", topup.createQR, {
    onSuccess: (response) => {
      setOpen(true);
      setData((prev: any) => ({
        ...prev,
        qr_string: response.data.qr_string,
        reference_id: response.data.reference_id,
      }));
      form.setValue("reference_id", response.data.reference_id);
      startRefetching();
      setTimeout(expiredFunction, 200000);
    },
    onError: () => {
      Swal.fire({
        title: `Error`,
        text: `Error Create QR ${data.wristband_code} wristband`,
        icon: "error",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {
    Swal.fire({
      title: "Confirm Topup?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        createTopup.mutate({
          data: values,
        });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const onCreateQR = (values: any) => {
    createQR.mutate({
      data: values,
    });
  };
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const fetchStatus = async (): Promise<void> => {
    try {
      const response = await topup.getTopupStatus({
        data: {
          reference_id: form.getValues("reference_id"),
        },
      });
      if (response.success === true) {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
        navigate("/app/topup");
        Swal.fire({
          title: `Success Topup`,
          html: `Success topup ${
            data.wristband_code
          } with amount ${form.getValues("amount")} token <br/>
          <strong>Total Token : ${
            data.balance + form.getValues("amount")
          }<strong>`,
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };

  const startRefetching = (): void => {
    intervalIdRef.current = setInterval(fetchStatus, 5000);
  };

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Topup</title>
      </Helmet>

      <Breadcrumb
        label={data.is_manual ? "Topup Manual" : "Topup Online"}
        breadcrumbs={[{ label: "Topup", href: "/app/topup" }]}
        href={"/app/topup"}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Typography variant="h2" textAlign="center" fontWeight="700">
          Topup
        </Typography>

        <div>
          <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={true}
            qrCodeSuccessCallback={onNewScanResult}
          />
        </div>

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <QRCode
                  value={data.qr_string}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
                />
              </Box>
              <Typography
                variant="h3"
                textAlign="center"
                fontWeight="700"
                marginTop="5px"
                color="white"
              >
                {rupiah(
                  form.getValues("amount") * data.token_price +
                    form.getValues("topup_commission")
                )}
              </Typography>
              <Typography
                variant="h3"
                textAlign="center"
                fontWeight="700"
                marginTop="5px"
                color="white"
              >
                Expired at{" "}
                {moment().add(3, "minute").format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </Box>
          </Modal>
        </div>

        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Wristband</FormLabel>
                <Controller
                  name="wristband_code"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <Box>
                      <Box>
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                        <Button
                          onClick={() =>
                            validateWristband(form.getValues("wristband_code"))
                          }
                        >
                          Validate
                        </Button>
                      </Box>
                      <Typography
                        variant="h6"
                        textAlign="left"
                        fontWeight="700"
                        marginTop="5px"
                      >
                        Current Balance: {form.getValues("balance")}
                      </Typography>
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Current Balance</FormLabel>
                <Controller
                  name="balance"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                      disabled
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Top Up Token</FormLabel>
                <Controller
                  name="amount"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                      disabled={data.is_manual}
                    />
                  )}
                />
              </FormControl>
              {!isXendit && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Voucher</FormLabel>
                  <Controller
                    name="voucher_id"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <Box>
                        <Stack direction="row" spacing={1}>
                          <Select
                            {...field}
                            size="small"
                            error={Boolean(fieldState.error?.message)}
                            fullWidth
                            onChange={(e: any) => {
                              setData((prev: any) => ({
                                ...prev,
                                isValidVoucher: false,
                              }));
                              const voucher = find(listVoucher.data.data, {
                                id: Number(e.target.value),
                              });
                              form.setValue("amount", voucher?.amount);
                              form.setValue(
                                "voucher_id",
                                Number(e.target.value)
                              );
                            }}
                          >
                            <MenuItem value="0">Select Voucher</MenuItem>
                            {get(listVoucher, ["data", "data"], []).map(
                              (item: any, i: any) => (
                                <MenuItem value={item?.id} key={item?.id}>
                                  {item?.code}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </Stack>

                        <FormHelperText
                          error={Boolean(fieldState.error?.message)}
                        >
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Box>
                    )}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Box>
        <Stack>
          <Typography>
            Total :{" "}
            {rupiah(
              form.getValues("amount") * data.token_price +
                form.getValues("topup_commission")
            )}
          </Typography>
        </Stack>
        {!isXendit ? (
          <Box sx={{ mt: 3 }}>
            <Stack direction="row" spacing={1}>
              <LoadingButton
                variant="contained"
                onClick={form.handleSubmit(onSubmit)}
                loading={createTopup.isLoading}
                disabled={!data.token_price}
              >
                SAVE
              </LoadingButton>
            </Stack>
          </Box>
        ) : (
          <Box sx={{ mt: 3 }}>
            <Stack direction="row" spacing={1}>
              <LoadingButton
                variant="contained"
                onClick={form.handleSubmit(onCreateQR)}
                loading={listVoucher.isLoading}
                disabled={!data.token_price}
              >
                Generate QR Code
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </ProtectedPermissionPage>
    </MainLayout>
  );

  function validateWristband(decodedText: string) {
    wristband
      .scanWristband({
        wristband: decodedText,
        event_id: user.event_id,
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband_code: decodedText,
          message: response.message,
          error: "",
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
        form.setValue("wristband_code", decodedText);
        form.setValue("balance", response.data?.token);
        form.setValue(
          "topup_commission",
          response.data?.event?.topup_commission
        );
      })
      .catch((response: any) => {
        if (response.response?.status === 404) {
          Swal.fire({
            title: `Error`,
            text: `Wristband ${decodedText} not found`,
            icon: "error",
          });
        }
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband_code: decodedText,
          message: "Scan another wristband!",
          error: response.response.data.error,
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
      });
  }
}
