import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const getAllEvent = async (params: any) => {
  const url = endpoints.EVENT;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const viewEventDetail = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.VIEW_EVENT(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createEvent = async (params: any) => {
  const url = endpoints.CREATE_EVENT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteEvent = async (id: number) => {
  const url = endpoints.DELETE_EVENT(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateEvent = async (params: any) => {
  const url = endpoints.VIEW_EVENT(params?.data.eventId);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadFile = async (path: any) => {
  const url = endpoints.DOWNLOAD_EXCEL;
  await axios
    .post(
      url,
      { path },
      {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      }
    )
    .then((res) => {
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, "wristbands.xlsx");
    });
};

const resetEvent = async (params: any) => {
    const url = endpoints.RESET_EVENT;
    const { data: response } = await axios.post(url, params, {
      headers: {
        Authorization: accessToken,
      },
    });

    return response;
};

const service = {
  getAllEvent,
  viewEventDetail,
  createEvent,
  deleteEvent,
  updateEvent,
  downloadFile,
  resetEvent
};

export default service;
