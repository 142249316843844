const config = {
    DEFAULT_PAGE_ROWS: 10,
    DEFAULT_PAGE_SIZE: [10, 20, 50],
    MONTH: {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    },
    NOT_FOR_REDEMPTION: [
      1608,
      2099,
      1296,
      1351,
      1366,
      2030,
      2098,
      2111,
      2113,
      2114,
      2116,
      2117,
      1271,
      1272,
      1682,
      2100
    ],
    onEnter: (func: any) => ({
      onKeyPress: (event: any) => {
        if (event.charCode === 13) {
          func()
        }
      }
    })
  }
;

export default config;
