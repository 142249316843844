import { useNavigate } from "react-router-dom";
import { useAppSelector } from "./useSelector";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import endpoints from "../utils/endpoints";
import axios from "axios";
import get from "lodash/get";
import { actions } from "../store/reducers/global";
import { accessToken as access } from "../utils/token";
import useNotification from "./useNotification";

export default function useAuth() {
  const notification = useNotification()
  const [accessToken, setAccessToken] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const dispatch = useDispatch();
  const { member } = useAppSelector((state) => state.global);

  const navigate = useNavigate();

  const getProfile = async (userId: any) => {
    const url = endpoints.PROFILE(userId);

    try {
      const { data: response } = await axios.get(url, {
        headers: {
          Authorization: access,
        },
      });

      dispatch(actions.getProfileSuccess({ ...get(response, "data"), userId }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        notification.onOpen({
          message: 'Your session has been expired',
          type: 'error',
          position: 'top'
        })

        CookieJs.remove('ID_USER_ACCOUNT')
        CookieJs.remove('ID_USER')
        CookieJs.remove('USER')
        window.location.href = "/"
      }
    }
  };

  useEffect(() => {
    const getToken = async () => {
      const access: any = CookieJs.get("ID_USER_ACCOUNT");
      const userId: any = CookieJs.get("ID_USER");

      setAccessToken(access);
      setMemberId(userId);

      if (access) {
        getProfile(userId);
      }

      if (!access) {
        navigate("/");
      }
    };

    getToken();
  }, []);

  return {
    profile: {
      ...member,
      user_id: memberId,
      userId: memberId,
    },
    accessToken,
  };
}
