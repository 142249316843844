import { DoNotDisturb } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function PageNotFound(props: any) {
  const { fullWidth } = props;

  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: fullWidth ? '100%' : 'calc(100vh - 80px)',
        flexDirection: 'column'
      }}
    >
      <Box>
        <DoNotDisturb sx={{ fontSize: 48, color: '#4D4D4F' }} />
      </Box>

      <Typography fontSize="20px" fontWeight="600" sx={{ mt: 2 }}>
        PAGE NOT FOUND
      </Typography>
    </Box>
  );
}
