import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import service from "../services/voucher";
import config from "../utils/config";
import { rupiah } from "../utils/currency";
import queryDefault from "../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function Voucher(params: any) {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const [options, setOptions] = useState<any>({
    keyword: "",
    event_id: user?.event_id,
  });
  const listVoucher = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return service.getVoucherByEventId({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  useEffect(() => {
    setOptions((prev: any) => ({
      ...prev,
      event_id: user.event_id,
    }));
  }, [user]);

  const form = useForm({
    defaultValues: {
      keyword: "",
      event_id: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);
  return (
    <MainLayout>
      <Helmet>
        <title>Voucher List</title>
      </Helmet>

      <Breadcrumb
        label="Voucher List"
        breadcrumbs={[{ label: "Voucher Management", href: "/app/voucher" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>

      <Box sx={{ mt: 2 }}>
        <Stack direction="row" spacing={1}>
          <ProtectedPermissionPage acceptPermissions={[1]}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/voucher/create-voucher"
            >
              ADD VOUCHER
            </Button>
          </ProtectedPermissionPage>
        </Stack>
      </Box>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          autoHeight
          loading={listVoucher.isLoading}
          getRowId={(record) => get(record, "id")}
          rows={get(listVoucher, "data.data", [])}
          rowCount={get(listVoucher, "data.data", []).length}
          page={options.page_number}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          disableSelectionOnClick
          onPageChange={(newPage) => {
            setOptions((prev: any) => ({ ...prev, page_number: newPage }));
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            NoRowsOverlay: CustomNoRowOverlay,
          }}
          columns={[
            {
              field: "code",
              headerName: "Voucher Code",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "quota",
              headerName: "Quota",
              flex: 1,
              filterable: false,
              minWidth: 150,
            },
            {
              field: "amount",
              headerName: "Token",
              flex: 1,
              filterable: false,
              minWidth: 150,
            },
            {
              field: "price",
              headerName: "Price",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{rupiah(params.value)}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "actions",
              headerName: "Action",
              sortable: false,
              filterable: false,
              width: 200,
              renderCell: (params) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={RouterLink}
                      to={`/app/voucher/${get(params, "row.id")}`}
                    >
                      Detail
                    </Button>
                  </Stack>
                );
              },
            },
          ]}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
          }}
        />
      </Box>
    </MainLayout>
  );
}
