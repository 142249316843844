export const API_URL = process.env.REACT_APP_BACKEND_URL;
const endpoints = {
  // Auth
  SIGN_IN: API_URL + "/api/v1/auth/signin",
  PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  FORGOT_PASSWORD: API_URL + "/api/v1/admin/forgot-password",
  RESET_PASSWORD: API_URL + "/api/v1/admin/reset-password",
  EDIT_PROFILE: (userId: any) => API_URL + `/api/v1/users/${userId}`,
  CHANGE_PASSWORD: API_URL + "/api/v1/users/change-password",

  // Role
  VIEW_LIST_ROLE: API_URL + "/api/v1/roles",

  // Users
  VIEW_LIST_USER: API_URL + "/api/v1/users",
  SEARCH_LIST_USER: API_URL + "/api/v1/admin/search-users",
  CREATE_USER: API_URL + "/api/v1/users",
  VIEW_USER: (viewId: any) => API_URL + `/api/v1/users/${viewId}`,

  // Events
  EVENT: API_URL + "/api/v1/events",
  CREATE_EVENT: API_URL + "/api/v1/events",
  RESET_EVENT: API_URL + `/api/v1/events/reset`,
  VIEW_EVENT: (id: number) => API_URL + `/api/v1/events/${id}`,
  DELETE_EVENT: (id: number) => API_URL + `/api/v1/events/${id}`,

  // Merchant
  MERCHANT: API_URL + "/api/v1/merchants",
  CREATE_MERCHANT: API_URL + "/api/v1/merchants",
  RESET_BALANCE_MERCHANT: API_URL + "/api/v1/merchants/reset",
  MERCHANT_USERS: API_URL + "/api/v1/merchant-users",
  VIEW_MERCHANT: (merchantId: number) => API_URL + `/api/v1/merchants/${merchantId}`,
  DELETE_MERCHANT: (id: number) => API_URL + `/api/v1/merchants/${id}`,
  
  // Item
  ITEM: API_URL + "/api/v1/items",
  CREATE_ITEM: API_URL + `/api/v1/items`,
  VIEW_ITEM: (itemId: number) => API_URL + `/api/v1/items/${itemId}`,
  DELETE_ITEM: (itemId: number) => API_URL + `/api/v1/items/${itemId}`,

  // Category
  CATEGORY: API_URL + `/api/v1/categories`,
  CATEGORY_DETAIL: (id: number) => API_URL + `/api/v1/categories/${id}`,
  CATEGORY_PDF: API_URL + `/api/v1/categories/pdf`,
  CATEGORY_PDF_ONLY_QR: API_URL + `/api/v1/categories/pdfonlyqr`,
  CATEGORY_RESET: API_URL + `/api/v1/categories/reset`,
  CATEGORY_RESET_REDEEM: API_URL + `/api/v1/categories/resetredeem`,
  UPLOAD_IMAGE: API_URL + "/api/v1/categories/upload",
  ADDITIONAL_WRISTBAND: API_URL + "/api/v1/categories/additionalwristband",
  UPDATE_SHOWED_NAME: API_URL + "/api/v1/categories/showedname",
  WRISTBAND_IDS: (id: number) =>
    API_URL + `/api/v1/categories/wristbands/${id}`,
  REDEEM_ACTIVE_STATUS: (id: number) =>
    API_URL + `/api/v1/categories/redeemstatus/${id}`,

  // Vouchers
  VOUCHER: API_URL + `/api/v1/voucher`,
  TOPUP: API_URL + `/api/v1/topup`,
  FLAG_TOPUP: API_URL + `/api/v1/topup/flag`,
  PROCEED_TOPUP: API_URL + `/api/v1/topup/proceed`,

  // Wristband
  WRISTBAND: API_URL + "/api/v1/wristband",
  VIEW_WRISTBAND: (id: number) => API_URL + `/api/v1/wristband/${id}`,
  UPLOAD_WRISTBAND: API_URL + "/api/v1/wristband/upload",
  FLAG_WRISTBAND: API_URL + "/api/v1/wristband/flag",
  SCAN_WRISTBAND: API_URL + "/api/v1/wristband/scan",
  REDEEM: API_URL + "/api/v1/redeem",

  // Transaction
  REFUND: API_URL + "/api/v1/transaction/refund",
  ORDER: API_URL + "/api/v1/transaction/order",

  // Log
  REFUND_LOG: (id: number) => API_URL + `/api/v1/transaction/refund-log/${id}`,
  TOPUP_LOG: (id: number) => API_URL + `/api/v1/transaction/topup-log/${id}`,
  ORDER_LOG: (id: number) => API_URL + `/api/v1/transaction/order-log/${id}`,
  CHECKOUT_STATUS: (id: number) =>
    API_URL + `/api/v1/events/checkout-status/${id}`,
  CHANGE_CHECKOUT_STATUS: (id: number) => API_URL + `/api/v1/checkout/${id}`,
  DOWNLOAD_EXCEL: API_URL + `/api/v1/events/download`,
  
  // CHECK
  CHECK: API_URL + "/api/v1/check",
};

export default endpoints;
