import { Close, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import moment from "moment";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { ReactComponent as ErrorLogo } from "../assets/images/error.svg";
import { ReactComponent as SuccessLogo } from "../assets/images/success.svg";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import service from "../services/topup";
import config from "../utils/config";
import { rupiah } from "../utils/currency";
import queryDefault from "../utils/queryDefault";

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F2F2F2",
  border: "2px solid #6b4a94",
  boxShadow: 24,
  p: 4,
};

export default function TopUpFlag() {
  const realPassword = process.env.REACT_APP_PASS_TOPUP;
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState<any>({
    keyword: "",
    username: user.username,
    flagged: true
  });
  const formModal = useForm({
    defaultValues: {
      name: "",
      email: "",
      mobile_no: "",
      password: "",
      username: user.username,
    }
  });

  const handleClose = () => {
    setOpen(false);
    formModal.setValue("name", "");
    formModal.setValue("email", "");
    formModal.setValue("mobile_no", "");
    formModal.setValue("password", "");
    setIsSuccess(false);
    setIsError(false);
  };

  const listTopup = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return service.getAllTopUpByUser({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });
  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const notification = useNotification();
  const flag = useMutation("flag", service.flagTopup, {
    onError: () => {
      notification.onOpen({
        message: "Flag not successful",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const handleConfirm = () => {
    if (formModal.getValues().password !== realPassword) {
      notification.onOpen({
        message: "Password incorrect",
        type: "error",
        position: "top",
      });
    } else {
      service.proceedTopup({
        id: id,
        name: formModal.getValues().name,
        email: formModal.getValues().email,
        mobile_no: formModal.getValues().mobile_no,
        username: user.username
      }).then(() => {
        setIsSuccess(true);
        listTopup.refetch();
      }).catch(() => {
        setIsError(true);
        listTopup.refetch();
      });
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>TopUp Flagged</title>
      </Helmet>

      <Breadcrumb
        label="Top Up List"
        breadcrumbs={[{ label: "Top Up", href: "/app/topup" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listTopup.isLoading}
            getRowId={(record) => get(record, "transaction_id")}
            rows={get(listTopup, "data.data", [])}
            rowCount={get(listTopup, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "ref_number",
                headerName: "Ref Number",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "amount",
                headerName: "Token",
                flex: 1,
                filterable: false,
                minWidth: 80,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "total_amount",
                headerName: "Amount",
                flex: 1,
                filterable: false,
                minWidth: 130,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{rupiah(params.value)}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                filterable: false,
                minWidth: 120,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography color={params.value === 'PENDING' ? 'red' : 'green'}>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "created_at",
                headerName: "Time",
                flex: 1,
                filterable: false,
                minWidth: 180,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY hh:mm:ss");
                },
              },
              {
                field: "is_manual",
                headerName: "Type",
                flex: 1,
                filterable: false,
                minWidth: 100,
                renderCell: (params) => {
                  return params.value === true ? "Manual" : "Online";
                },
              },
              {
                field: "flagged_time",
                headerName: "Flagged Time",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 180,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY hh:mm:ss");
                },
              },
              {
                field: "flagged_by",
                headerName: "Flagged By",
                flex: 1,
                filterable: false,
                minWidth: 120,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 120,
                renderCell: (params) => {
                  if (get(params, "row.status") === "SUCCESS") {
                    return <></>
                  }
                  return (
                    <Stack direction="row" spacing={1}>
                      <LoadingButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setId(get(params, "row.id"));
                          setOpen(true);
                        }}
                        loading={flag.isLoading}
                      >
                        Proceed
                      </LoadingButton>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style} textAlign="center">
            <Stack direction="column" spacing={1}>
              {isSuccess && (
                <>
                  <Typography>
                    <SuccessLogo />
                    <Typography variant="h6">
                      Success Proceed Topup
                    </Typography>
                  </Typography>
                </>
              )}
              {isError && (
                <Typography>
                  <ErrorLogo />
                  <Typography variant="h6">Failed Proceed Topup</Typography>
                </Typography>
              )}
              {!(isSuccess || isError) && (
                <Box>
                  <FormControl fullWidth>
                    <FormLabel>Name</FormLabel>
                    <Controller
                      name="name"
                      control={formModal.control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>Email</FormLabel>
                    <Controller
                      name="email"
                      control={formModal.control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>Mobile Number</FormLabel>
                    <Controller
                      name="mobile_no"
                      control={formModal.control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel>Password</FormLabel>
                    <Controller
                      name="password"
                      control={formModal.control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          {...field}
                          inputRef={ref}
                          error={Boolean(fieldState.error?.message)}
                          helperText={fieldState.error?.message}
                          size="small"
                          type="password">
                        </TextField>
                      )}
                    />
                  </FormControl>
                  <Button variant="contained" onClick={handleConfirm} sx={{ marginTop: '20px' }}>
                    Confirm
                  </Button>
                </Box>
              )}
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
