import axios from "axios";
import endpoints from "../utils/endpoints";

const scan = async (params: any) => {
  const url = endpoints.CHECK;
  const { data: response } = await axios.post(url, params);
  return response;
};

const service = {
  scan
};

export default service;
