import CookieJs from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "./useSelector";

export default function useAutomaticLogIn() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.global);

  useEffect(() => {
    const getToken = async () => {
      const access: any = await CookieJs.get("ID_USER_ACCOUNT");

      if (access) {
        navigate("/app")
      }
    };

    getToken();
  }, []);
}
