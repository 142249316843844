import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import check from "../services/check";
import config from "../utils/config";

function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}

export default function Check() {
  let audio = new Audio("/beep.mp3");
  const checkQrShown = () => {
    const qr = document.getElementById("html5qr-code-full-region");
    if (qr?.innerHTML === "") {
      window.location.reload();
    }
  };
  setTimeout(checkQrShown, 500);
  const [data, setData] = useState({
    wristband_id: 0,
    wristband: "",
    message: "Start Scanning!",
    error: "",
    balance: 0,
    event_name: "",
    transactions: []
  });

  const scanAnother = () => {
    setData((prev: any) => ({
      ...prev,
      wristband_id: 0,
      wristband: "",
      message: "Start Scanning!",
      error: "",
      balance: 0,
      transactions: []
    }))
  };

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    audio.play();
    check
      .scan({
        wristband: decodedText,
        signature: btoa(decodedText + process.env.REACT_APP_TOKEN_CHECK),
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: response.message,
          error: "",
          balance: response.data?.token,
          event_name: response.data?.event?.name,
          transactions: response.data?.transactions
        }));
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: "Scan another wristband!",
          error: response.response.data.error,
          balance: response.data?.token,
          event_name: response.data?.event?.name,
          transactions: []
        }));
      });
  };

  const checkManual = (values: { keyword: any }) => {
    onNewScanResult(values.keyword, "");
  };

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  return (
    <Box sx={{px:0}}>
      <Helmet>
        <title>Check Balance</title>
      </Helmet>

      <Typography variant="h2" textAlign="center" fontWeight="700">
        Check Balance
      </Typography>

      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResult}
      />

      <Box
        mt={3}
        sx={{
          border: "1px solid rgba(224, 224, 224, 1)",
          padding: "10px",
          color: "rgba(0, 0, 0, 0.87)",
          textAlign: "center"
        }}
        className={"box-check"}
      >
        <Grid container spacing={4}>
          <Grid item lg={4} md={12} sm={12}>
            <Typography variant="h2">
              Event: {data.event_name !== "" ? data.event_name : "-"}
            </Typography>
          </Grid>
          <Grid item lg={4} md={12} sm={12}>
            <Typography variant="h2">
              Wristband: {data.wristband !== "" ? data.wristband : "-"}
            </Typography>
          </Grid>
          <Grid item lg={4} md={12} sm={12}>
            <Typography variant="h2">
              Balance: {data.balance}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ margin: "auto" }}
              disabled={data.wristband_id === 0}
              onClick={scanAnother}
            >
              Scan Another
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h2" sx={{ marginBottom: "10px" }}>
            Transaction History
          </Typography>
          <DataGrid
            autoHeight
            getRowId={(record) => get(record, "id")}
            rows={data.transactions}
            rowCount={data.transactions?.length}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: noRowsOverlay,
            }}
            columns={[
              {
                field: "created_by",
                headerName: "PIC",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
              },
              {
                field: "total_amount",
                headerName: "Amount",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 100,
              },
              {
                field: "type",
                headerName: "Type",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
                renderCell: (params) =>
                  <Typography
                    variant="body2"
                    color={params.value === 'Topup' ? 'green' : params.value === "Refund" ? 'red' : 'blue'}
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {params.value}
                  </Typography>
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Stack direction="row" spacing={1}>
          <Controller
            name="keyword"
            control={form.control}
            render={({ field }) => (
              <TextField
                variant="outlined"
                size="small"
                placeholder="Input Wristband Id here"
                {...field}
                {...config.onEnter(() => {
                  form.handleSubmit(checkManual)();
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        if (field.value) {
                          field.onChange("");
                        }
                      }}
                    >
                      {field.value ? (
                        <Close color="error" sx={{ cursor: "pointer" }} />
                      ) : (
                        <Search />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Button
            variant="outlined"
            onClick={form.handleSubmit(checkManual)}
          >
            Input Manual
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
