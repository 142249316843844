import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import voucherService from "../services/voucher";
import queryDefault from "../utils/queryDefault";

export default function VoucherCreate() {
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    code: yup.string().required("role is a required field"),
    quota: yup.number().required("quota is required"),
    amount: yup.number().required("amount is required"),
  });
  const form = useForm({
    defaultValues: {
      event_id: user?.event_id,
      code: "",
      quota: 0,
      amount: 0,
      username: user?.username,
    },
    resolver: yupResolver(schema),
  });

  const createVoucher = useMutation(
    "create_voucher",
    voucherService.createVoucher,
    {
      onSuccess: () => {
        navigate("/app/voucher/");

        notification.onOpen({
          message: "Voucher was successfully created!",
          type: "success",
          position: "top",
        });
      },
      onError: () => {
        notification.onOpen({
          message: "Something went wrong",
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const onSubmit = (values: any) => {
    createVoucher.mutate({
      data: values,
    });
  };
  return (
    <MainLayout>
      <Breadcrumb
        label={"Create Voucher"}
        breadcrumbs={[
          { label: "Voucher", href: "/app/voucher" },
          {
            label: "Create Event",
            href: "/app/voucher/create-voucher",
          },
        ]}
        href={"/app/voucher"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel required>Code</FormLabel>
                <Controller
                  name="code"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      size="small"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Quota</FormLabel>
                  <Controller
                    name="quota"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Amount</FormLabel>
                  <Controller
                    name="amount"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createVoucher.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
