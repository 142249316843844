import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import moment from "moment";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import service from "../services/topup";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";
import { rupiah } from "../utils/currency";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import { LoadingButton } from "@mui/lab";
import useNotification from "../hooks/useNotification";

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function TopUpList() {
  const [pageSize, setPageSize] = useState<number>(10);
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [options, setOptions] = useState<any>({
    keyword: "",
    username: user.username,
  });
  const listTopup = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return service.getAllTopUpByUser({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });
  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const notification = useNotification();
  const flag = useMutation("flag", service.flagTopup, {
    onError: () => {
      notification.onOpen({
        message: "Flag not successful",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const handleFlag = async (params: any) => {
    flag.mutate(params);
    await timeout(500);
    listTopup.refetch();
  };

  return (
    <MainLayout>
      <Helmet>
        <title>TopUp List</title>
      </Helmet>

      <Breadcrumb
        label="Top Up List"
        breadcrumbs={[{ label: "Top Up", href: "/app/topup" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>

      <Box sx={{ mt: 2 }}>
        <Stack direction="row" spacing={1}>
          <ProtectedPermissionPage acceptPermissions={[1, 2]}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/topup/create"
            >
              TOPUP MANUAL
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/topup/create-xendit"
            >
              TOPUP ONLINE
            </Button>
          </ProtectedPermissionPage>
        </Stack>
      </Box>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          autoHeight
          loading={listTopup.isLoading}
          getRowId={(record) => get(record, "transaction_id")}
          rows={get(listTopup, "data.data", [])}
          rowCount={get(listTopup, "data.data", []).length}
          page={options.page_number}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          disableSelectionOnClick
          onPageChange={(newPage) => {
            setOptions((prev: any) => ({ ...prev, page_number: newPage }));
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            NoRowsOverlay: CustomNoRowOverlay,
          }}
          columns={[
            {
              field: "ref_number",
              headerName: "Ref Number",
              flex: 1,
              filterable: false,
              minWidth: 200,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "amount",
              headerName: "Token",
              flex: 1,
              filterable: false,
              minWidth: 80,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "total_amount",
              headerName: "Amount",
              flex: 1,
              filterable: false,
              minWidth: 130,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{rupiah(params.value)}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "status",
              headerName: "Status",
              flex: 1,
              filterable: false,
              minWidth: 120,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography
                      color={
                        params.value === "PENDING"
                          ? "red"
                          : params.value === "EXPIRED"
                          ? "orange"
                          : "green"
                      }
                    >
                      {params.value}
                    </Typography>
                  </Stack>
                );
              },
            },
            {
              field: "created_at",
              headerName: "Time",
              flex: 1,
              filterable: false,
              minWidth: 180,
              renderCell: (params) => {
                if (!params.value) {
                  return "-";
                }

                return moment(params.value).format("DD/MM/YYYY hh:mm:ss");
              },
            },
            {
              field: "code",
              headerName: "Voucher Code",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "is_manual",
              headerName: "Type",
              flex: 1,
              filterable: false,
              minWidth: 150,
              renderCell: (params) => {
                return params.value === true ? "Manual" : "Online";
              },
            },
            {
              field: "is_flagged",
              headerName: "Flagged",
              flex: 1,
              sortable: false,
              filterable: false,
              minWidth: 100,
              renderCell: (params) => {
                return params.value === true ? (
                  <Typography
                    variant="body2"
                    color="red"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    Flagged
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    Not Flagged
                  </Typography>
                );
              },
            },
            {
              field: "actions",
              headerName: "Action",
              sortable: false,
              filterable: false,
              width: 200,
              renderCell: (params) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={RouterLink}
                      to={`/app/wristband/${get(params, "row.id")}`}
                    >
                      Detail
                    </Button>
                    <ProtectedPermissionView acceptPermissions={[1]}>
                      {params.row.is_flagged ? (
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handleFlag({
                              id: get(params, "row.id"),
                              username: user.username,
                            })
                          }
                          loading={flag.isLoading}
                        >
                          Unflag
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handleFlag({
                              id: get(params, "row.id"),
                              username: user.username,
                            })
                          }
                          loading={flag.isLoading}
                        >
                          Flag
                        </LoadingButton>
                      )}
                    </ProtectedPermissionView>
                  </Stack>
                );
              },
            },
          ]}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
          }}
        />
      </Box>
    </MainLayout>
  );
}
