import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import CustomPagination from "../components/CustomPagination";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import service from "../services/wristband";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";
import useNotification from "../hooks/useNotification";
import { LoadingButton } from "@mui/lab";

function timeout(delay: number) {
  return new Promise(res => setTimeout(res, delay));
}

function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}
export default function Wristband() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [options, setOptions] = useState<any>({
    keyword: "",
    page_number: 0,
    per_page: config.DEFAULT_PAGE_ROWS,
    event_id: 0,
  });
  const listWristband = useQuery(
    ["wristband", options],
    ({ queryKey }) => {
      return service.getAllWristbandByEventId({
        ...queryKey[1],
        page_number: get(queryKey, [1, "page_number"], 0) + 1,
        event_id: user.event_id,
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const notification = useNotification();
  const flag = useMutation("flag", service.flagWristband, {
    onError: () => {
      notification.onOpen({
        message: "Flag not successful",
        type: "error",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const handleFlag = async (params: any) => {
    flag.mutate(params);
    await timeout(500);
    listWristband.refetch();
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Wristband</title>
      </Helmet>

      <Breadcrumb
        label="Wristband"
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                              page: 0,
                              perPage: config.DEFAULT_PAGE_ROWS,
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              Search
            </Button>
            <Button variant="outlined" onClick={() => listWristband.refetch()}>
              Refresh
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listWristband.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listWristband, "data.data", [])}
            rowCount={get(listWristband, "data.countData", 0)}
            page={options.page_number}
            pageSize={options.per_page}
            rowsPerPageOptions={config.DEFAULT_PAGE_SIZE}
            paginationMode="server"
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => {
              setOptions((prev: any) => ({
                ...prev,
                per_page: newPageSize,
                page_number: 0,
              }));
            }}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: noRowsOverlay,
            }}
            columns={[
              {
                field: "wristband",
                headerName: "Wristband",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 100,
              },
              {
                field: "token",
                headerName: "Token",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
              },
              {
                field: "is_flagged",
                headerName: "Flagged",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
                renderCell: (params) => {
                  return params.value === true ?
                    <Typography
                      variant="body2"
                      color="red"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      Flagged
                    </Typography>
                    : <Typography
                      variant="body2"
                      color="green"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      Not Flagged
                    </Typography>
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/wristband/${get(params, "row.id")}`}
                      >
                        Detail
                      </Button>
                      <ProtectedPermissionView acceptPermissions={[1]}>
                        {params.row.is_flagged ?
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={() => handleFlag({
                              id: get(params, "row.id"),
                              username: user.username
                            })}
                            loading={flag.isLoading}
                          >
                            Unflag
                          </LoadingButton> :
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={() => handleFlag({
                              id: get(params, "row.id"),
                              username: user.username
                            })}
                            loading={flag.isLoading}
                          >
                            Flag
                          </LoadingButton>}
                      </ProtectedPermissionView>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>

    </MainLayout>
  );
}
