const sideMenu = [
  {
    id: "events",
    label: "Events",
    href: "/app/event",
    permissions: [1]
  },
  {
    id: "merchants",
    label: "Merchants",
    href: "/app/merchant",
    permissions: [1, 3]
  },
  {
    id: "wristband",
    label: "Wristband",
    href: "/app/wristband",
    permissions: [1]
  },
  {
    id: "voucher",
    label: "Voucher",
    href: "/app/voucher",
    permissions: [1]
  },
  {
    id: "refund",
    label: "Refund",
    href: "/app/refund",
    permissions: [1, 4]
  },
  {
    id: "topup",
    label: "Topup",
    href: "/app/topup",
    permissions: [1, 2]
  },
  {
    id: "userlist",
    label: "User Management",
    href: "/app/users",
    permissions: [1]
  },
  {
    id: "topupFlag",
    label: "Proceed Topup",
    href: "/app/topup-flag",
    permissions: [1]
  }
];

export default sideMenu;
