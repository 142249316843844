import { createTheme } from "@mui/material/styles";
import themeTypography from "./typograpy";

// project imports
// import componentStyleOverrides from './compStyleOverride';
// import themePalette from './palette';
// import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = () => {
  const themes = createTheme({
    palette: {
      primary: {
        main: "#6b4a94",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#9c88bc",
      },
      error: {
        main: "#EB5757",
      },
      grey: {
        "200": "#939598",
        "400": "#E0E0E0",
        "500": "#4D4D4F",
        "600": "#333333",
      },
    },
    typography: themeTypography(),
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
          contained: {
            color: "#fff",
          },
          outlined: {
            borderWidth: 2,
            ":hover": {
              borderWidth: 2,
            },
          },
          outlinedPrimary: {
            color: "#6b4a94",
          },
          containedPrimary: {
            ":hover": {
              backgroundColor: "#9c88bc",
            },
          },
        },
        defaultProps: {
          disableElevation: true,
          disableRipple: true,
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& .Mui-disabled": {
              background: "#F2F2F2",
              backgroundColor: "#F2F2F2",
              border: "none",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "& .Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)",
            },
          },
        },

        defaultProps: {
          color: "primary",
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 8,
          },
          thumb: {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
            backgroundColor: "white",
          },
          track: {
            borderRadius: 22 / 2,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            fontWeight: 400,
          },
          asterisk: {
            color: "#EB5757",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .Mui-selected": {
              color: "#143784 !important",
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#44C8F5",
            fontWeight: 700,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            fontWeight: 700,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {},
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            "& .MuiDrawer-paper": {
              backgroundColor: "#a48cbc",
            },
          },
        },
      }
    },
  });

  return themes;
};

export default theme;
