import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import merchant from "../services/merchant";
import queryDefault from "../utils/queryDefault";

export default function MerchantItemEditPage() {
  const { itemId } = useParams();
  const location = useLocation();
  const path = location.pathname.split("/");
  const merchant_id = parseInt(path[3]);
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("Item name is a required field"),
    price: yup.string().required("Item price is a required field"),
  });
  const currentItem = useQuery("view-merchant", () => merchant.viewMerchantItemDetail(itemId));
  const form = useForm({
    defaultValues: {
      name: "",
      price: 0,
      merchant_id: merchant_id,
      username: user.username,
    },
    resolver: yupResolver(schema),
  });

  const createItem = useMutation("create_merchant", merchant.createMerchantItem, {
    onSuccess: () => {
      navigate(`/app/merchant/${merchant_id}/item`);

      notification.onOpen({
        message: "Item was successfully created!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const updateItem = useMutation("update-item", merchant.updateMerchantItem, {
    onSuccess: () => {
      navigate(`/app/merchant/${merchant_id}/item`);

      notification.onOpen({
        message: "Item was successfully updated!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const onSubmit = (values: any) => {   
    if (itemId) {
      updateItem.mutate({
        data: {
          ...values,
          itemId: itemId,
        },
      });
    } else {
      createItem.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (itemId) {
      form.setValue("name", get(currentItem, "data.data.name"));
      form.setValue("price", get(currentItem, "data.data.price"));
    }
  }, [currentItem, form, itemId, user]);

  return (
    <MainLayout>
      <Breadcrumb
        label={itemId ? "Edit Item" : "Create Item"}
        breadcrumbs={[
          { label: "Merchant", href: "/app/merchant" },
          {
            label: itemId ? "Edit Item" : "Create Item",
            href: "/app/merchants/create-merchant",
          },
        ]}
        href={"/app/merchant"}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 3]}>
        {!currentItem.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth>
                  <FormLabel required>Item Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel required>Item Price</FormLabel>
                  <Controller
                    name="price"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2, display: "none" }}>
                  <FormLabel required>Merchant Id</FormLabel>
                  <Controller
                    name="merchant_id"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}

                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createItem.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
