import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Stack,
  Typography
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

type BreadcrumbItem = {
  label: string | any;
  href?: string;
};

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
  label: string;
  href?: string;
  prevUrl?: string;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const {breadcrumbs, label, href, prevUrl} = props;
  const navigate = useNavigate()

  return (
    <Box>
      <Breadcrumbs separator={<ArrowRightRoundedIcon/>}>
        {breadcrumbs.map((item, i) => {
          if (!item.href) {
            return (
              <Typography
                variant="body1"
                fontWeight="300"
                color="grey.600"
              >
                {item.label}
              </Typography>
            )
          }

          return (
            <Link
              component={RouterLink}
              to={item.href}
              variant="body1"
              fontWeight="300"
              underline="none"
              color="grey.600"
              key={i}
            >
              {item.label}
            </Link>
          )
        })}
      </Breadcrumbs>

      <Stack direction="row" alignItems="start" spacing={1} sx={{mt: 1}}>
        {href && (
          <IconButton
            size="small"
            component={RouterLink}
            to={href}
            sx={{mt: 0.5}}
          >
            <ArrowBackIosRoundedIcon fontSize="inherit"/>
          </IconButton>
        )}

        {prevUrl && (
          <IconButton
            size="small"
            onClick={() => {
              if (window.history && window.history.state.idx > 0) {
                return navigate(-1)
              }

              return navigate(prevUrl)
            }}
            sx={{mt: 0.5}}
          >
            <ArrowBackIosRoundedIcon fontSize="inherit"/>
          </IconButton>
        )}

        <Typography variant="h1" color="grey.500">
          {label}
        </Typography>
      </Stack>
    </Box>
  );
}
