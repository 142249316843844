import { ButtonProps } from "@mui/material";
import { createContext, useMemo, useState } from "react";
import Alert from "../components/Alert";

interface AlertProps {
  title: string;
  message: string;
  actions: ButtonProps[];
}

export interface AlertContextProps {
  onOpen: (options: AlertProps) => void;
  onClose: () => void;
}

export const AlertContext = createContext<AlertContextProps>({
  onOpen: () => {},
  onClose: () => {},
});

export const AlertProvider = ({ children }: any) => {
  const [alert, setAlert] = useState<any>({
    open: false,
    title: "",
    message: "",
    actions: [],
  });

  const onOpen = (options: AlertProps) => {
    setAlert({
      open: true,
      title: options.title,
      message: options.message,
      actions: options.actions,
    });
  };

  const onClose = () => {
    setAlert((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const context = useMemo(() => ({ onOpen, onClose }), [onOpen, onClose]);

  return (
    <AlertContext.Provider value={context}>
      {children}

      <Alert
        open={alert.open}
        title={alert.title}
        message={alert.message}
        actions={alert.actions}
        onClose={onClose}
      />
    </AlertContext.Provider>
  );
};
