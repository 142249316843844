import { get } from "lodash";
import { Navigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks/useSelector";
import DashboardLayout from "../layouts/Dashboard";
import MinimalLayout from "../layouts/Minimal";
import AccountProfilePage from "../pages/AccountProfilePage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import Check from "../pages/Check";
import Event from "../pages/Event";
import EventDetail from "../pages/EventDetail";
import EventCreate from "../pages/EventEditPage";
import ForgotPage from "../pages/ForgotPage";
import LoadingPage from "../pages/LoadingPage";
import Merchant from "../pages/Merchant";
import MerchantDetail from "../pages/MerchantDetail";
import MerchantCreate from "../pages/MerchantEditPage";
import MerchantItemCreate from "../pages/MerchantItemEditPage";
import MerchantOrder from "../pages/MerchantOrder";
import PageNotFound from "../pages/PageNotFound";
import Refund from "../pages/Refund";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SignInPage from "../pages/SignInPage";
import TopUpCreate from "../pages/TopUpCreate";
import TopUpFlag from "../pages/TopUpFlag";
import TopUpList from "../pages/TopUpList";
import UserCreate from "../pages/UserCreate";
import UserEdit from "../pages/UserEdit";
import UserList from "../pages/UserList";
import Voucher from "../pages/Voucher";
import VoucherCreate from "../pages/VoucherCreate";
import VoucherDetail from "../pages/VoucherDetail";
import Wristband from "../pages/Wristband";
import WristbandDetail from "../pages/WristbandDetail";

export default function Routes() {
  const { user } = useAppSelector((state) => state.global);
  const chooseDefaultRoute: any = () => {
    const role = get(user, "role_id");
    if (role === 1) {
      return { element: <Navigate to="/app/event" />, index: true };
    }
    if (role === 2 || role === 3) {
      return { element: <Navigate to="/app/topup" />, index: true };
    }
    if (role === 4 && user.merchant_id > 0) {
      return { element: <Navigate to="/app/merchant" />, index: true };
    }
    if (role === 5) {
      return { element: <Navigate to="/app/refund" />, index: true };
    }
    return { element: <LoadingPage />, index: true };
  };
  return useRoutes([
    {
      path: "/app",
      element: <DashboardLayout />,
      children: [
        {
          path: "/app",
          children: [chooseDefaultRoute()],
        },
        {
          path: "/app",
          children: [{ element: <Navigate to="/app/event" />, index: true }],
        },
        {
          path: "/app/profile",
          children: [
            { element: <AccountProfilePage />, index: true },
            {
              element: <ChangePasswordPage />,
              path: "/app/profile/change-password",
            },
          ],
        },
        {
          path: "/app/event",
          children: [
            { element: <Event />, index: true },
            {
              element: <EventDetail />,
              path: "/app/event/:id",
            },
            {
              element: <EventCreate />,
              path: "/app/event/edit/:id",
            },
            {
              element: <EventCreate />,
              path: "/app/event/create-event",
            },
          ],
        },
        {
          path: "/app/merchant",
          children: [
            { element: <Merchant />, index: true },
            {
              element: <MerchantDetail />,
              path: "/app/merchant/:id/item",
            },
            {
              element: <MerchantCreate />,
              path: "/app/merchant/edit/:id",
            },
            {
              element: <MerchantCreate />,
              path: "/app/merchant/create-merchant",
            },
            {
              element: <MerchantItemCreate />,
              path: "/app/merchant/:merchantId/item/create-item",
            },
            {
              element: <MerchantItemCreate />,
              path: "/app/merchant/:merchantId/edit/item/:itemId",
            },
            {
              element: <MerchantOrder />,
              path: "/app/merchant/:id/order",
            }
          ],
        },
        {
          path: "/app/wristband",
          children: [
            { element: <Wristband />, index: true },
            {
              element: <WristbandDetail />,
              path: "/app/wristband/:id",
            },
          ],
        },
        {
          path: "/app/topup",
          children: [
            { element: <TopUpList />, index: true },
            { element: <TopUpCreate />, path: "/app/topup/create" },
            { element: <TopUpCreate />, path: "/app/topup/create-xendit" },
          ],
        },
        {
          path: "/app/topup-flag",
          children: [
            { element: <TopUpFlag />, index: true },
          ],
        },
        {
          path: "/app/voucher",
          children: [
            { element: <Voucher />, index: true },
            {
              element: <VoucherCreate />,
              path: "/app/voucher/create-voucher",
            },
            {
              element: <VoucherDetail />,
              path: "/app/voucher/:id",
            },
          ],
        },
        {
          path: "/app/refund",
          children: [{ element: <Refund />, index: true }],
        },
        {
          path: "/app/users",
          children: [
            { element: <UserList />, index: true },
            {
              element: <UserCreate />,
              path: "/app/users/create-user",
            },
            {
              element: <UserEdit />,
              path: "/app/users/edit/:id",
            },
          ],
        },
        {
          path: "/app/users",
          element: <Navigate to="/app/users/manage" />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth={false} />,
        },
      ],
    },
    {
      path: "/",
      element: <MinimalLayout />,
      children: [
        {
          path: "/",
          element: <SignInPage />,
        },
        {
          path: "/forgot-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ForgotPage />,
        },
        {
          path: "/check",
          element: <Check />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth />,
        },
      ],
    },
  ]);
}
