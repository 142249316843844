import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from 'js-cookie';
import get from "lodash/get";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ReactComponent as ErrorLogo } from "../assets/images/error.svg";
import { ReactComponent as SuccessLogo } from "../assets/images/success.svg";
import Breadcrumb from "../components/Breadcrumb";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useAlert from "../hooks/useAlert";
import merchant from "../services/merchant";
import transaction from "../services/transaction";
import wristband from "../services/wristband";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F2F2F2",
  border: "2px solid #6b4a94",
  boxShadow: 24,
  p: 4,
};

export default function MerchantOrder() {
  let audio = new Audio("/beep.mp3");
  const checkQrShown = () => {
    const qr = document.getElementById("html5qr-code-full-region");
    if (qr?.innerHTML === "") {
      window.location.reload();
    }
  };
  setTimeout(checkQrShown, 500);
  const user = JSON.parse(CookieJs.get("USER") || "");
  const alert = useAlert();
  const location = useLocation();
  const path = location.pathname.split("/");
  const merchant_id = path[3];
  const [pageSize, setPageSize] = useState<number>(20);
  const [options, setOptions] = useState<any>({
    keyword: "",
    merchant_id: merchant_id
  });
  const [cart, setCart] = useState<any>([]);
  const [name, setName] = useState(null);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState({
    wristband_id: 0,
    wristband: "",
    message: "Start Scanning!",
    error: "",
    balance: 0,
    event_id: user.event_id,
    token_price: 0,
    isSuccess: false,
    isError: false,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    scanAnother();
  };

  const scanAnother = () => {
    setData({
      wristband_id: 0,
      wristband: "",
      message: "Start Scanning!",
      error: "",
      balance: 0,
      event_id: user.event_id,
      token_price: 0,
      isSuccess: false,
      isError: false,
    });
  };

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    audio.play();
    wristband
      .scanWristband({
        wristband: decodedText,
        event_id: user.event_id,
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: response.message,
          error: "",
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
        document.getElementById("html5-qrcode-button-camera-stop")?.click();
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: "Scan another wristband!",
          error: response.response.data.error,
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
      });
  };

  const orderManual = (values: { keyword: any }) => {
    onNewScanResult(values.keyword, "");
  };

  const onOrder = () => {
    transaction
      .order({
        wristband_id: data.wristband_id,
        event_id: data.event_id,
        merchant_id: merchant_id,
        username: user.username,
        items: cart,
        amount: total
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          message: response.message,
          error: "",
          isSuccess: true,
          isError: false,
          balance: response.balance
        }));
        handleOpen();
        document.getElementById("html5-qrcode-button-camera-stop")?.click();
        var newCart: any = [];
        cart.forEach((row: any) => {
          newCart.push({
            id: row.id,
            price: row.price,
            qty: 0,
            name: row.name
          });
        })
        setCart(newCart);
        setTotal(0);
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          message: "Scan another wristband!",
          error: response.response.data?.error,
          isSuccess: false,
          isError: true,
        }));
        handleOpen();
      });
    alert.onClose();
  }

  const confirmOrder = () => {
    alert.onOpen({
      title: "Confirm Order",
      message: `Are you sure to create order items?${cart.filter((x: any) => x.qty > 0).map((item: any) => {
        return `\n${item.name} : ${item.qty}`;
      })}`,
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Order",
          onClick: () => onOrder(),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const listItem = useQuery(
    ["item", options],
    ({ queryKey }) => {
      const response = merchant.getAllItembyMerchantId({ ...queryKey[1] });
      response.then((result) => {
        var currentCart = cart;
        result.data?.rows.forEach((row: any) => {
          currentCart.push({
            id: row.id,
            price: row.price,
            qty: 0,
            name: row.name
          });
        })
        setCart(currentCart);
      });
      return response;
    },
    {
      ...queryDefault,
    }
  );

  useEffect(() => {
    merchant.viewMerchantDetail(merchant_id).then((response: any) => {
      setName(response.data.name)
    })
  }, [user, merchant_id]);

  const addQty = (id: number) => {
    var currentItem = cart.find((x: any) => x.id === id);
    const newcart = cart.map((item: any) => {
      if (item.id === id) {
        return { ...item, qty: currentItem.qty + 1 };
      }
      return item;
    });
    setCart(newcart);
    setTotal(total + currentItem.price);
  }

  const substractQty = (id: number) => {
    var currentItem = cart.find((x: any) => x.id === id);
    if (currentItem.qty > 0) {
      const newcart = cart.map((item: any) => {
        if (item.id === id) {
          return { ...item, qty: currentItem.qty - 1 };
        }
        return item;
      });
      setCart(newcart);
      setTotal(total - currentItem.price);
    }
  }

  return (
    <MainLayout>
      <Helmet>
        <title>Order</title>
      </Helmet>

      <Breadcrumb
        label={`Order ${name}`}
        breadcrumbs={[{ label: "Merchant", href: "/app/merchant" }]}
        href={"/app/merchant"}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 3]}>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style} textAlign="center">
            <Stack direction="column" spacing={1}>
              {data.isSuccess && (
                <Typography>
                  <SuccessLogo />
                </Typography>
              )}
              {data.isSuccess && (
                <>
                  <Typography variant="h6">
                    Wristband: {data.wristband}
                  </Typography>
                  <Typography variant="h6">Remaining Balance: {data.balance}</Typography>
                  <Typography variant="h6" marginTop="10">
                    {data.message}
                  </Typography>
                </>
              )}
              {data.isError && (
                <Typography>
                  <ErrorLogo />
                  {data.wristband_id > 0 && (
                    <>
                      <Typography variant="h6">
                        Wristband: {data.wristband}
                      </Typography>
                      <Typography variant="h6">
                        Remaining Balance: {data.balance}
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6">{data.error}</Typography>
                </Typography>
              )}
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listItem.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listItem, "data.data.rows", [])}
            rowCount={get(listItem, "data.data.rows", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ minWidth: '25px' }}
                        onClick={() => substractQty(get(params, "row.id"))}
                      > - </Button>
                      <Box sx={{
                        alignContent: 'center',
                        textAlign: 'center',
                        border: '2px solid rgba(107, 74, 148, 0.6)',
                        borderRadius: '5px',
                        minWidth: '30px'
                      }}>
                        {cart.find((x: any) => x.id === get(params, "row.id"))?.qty}
                      </Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ minWidth: '25px' }}
                        onClick={() => addQty(get(params, "row.id"))}
                      > + </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />

          <Box sx={{
            textAlign: 'right',
            paddingRight: '40px',
            mt: 2,
            mb: 2
          }}>
            <b>Total: {total} token</b>
          </Box>

          <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
          />

          <Box
            mt={3}
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              padding: "10px",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <Grid container spacing={{ lg: 4, md: 2 }}>
              <Grid item lg={6} md={12}>
                Wristband: {data.wristband !== "" ? data.wristband : "-"}
              </Grid>
              <Grid item lg={6} md={12}>
                Balance: {data.balance}
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ margin: "auto" }}
                  disabled={data.wristband_id === 0}
                  onClick={scanAnother}
                >
                  Scan Another
                </Button>
                <Button
                  variant="contained"
                  sx={{ margin: "auto" }}
                  disabled={data.balance <= 0}
                  onClick={confirmOrder}
                >
                  Confirm Order
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Stack direction="row" spacing={1}>
              <Controller
                name="keyword"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Input Wristband Id here"
                    {...field}
                    {...config.onEnter(() => {
                      form.handleSubmit(orderManual)();
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            if (field.value) {
                              field.onChange("");
                            }
                          }}
                        >
                          {field.value ? (
                            <Close color="error" sx={{ cursor: "pointer" }} />
                          ) : (
                            <Search />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                variant="outlined"
                onClick={form.handleSubmit(orderManual)}
              >
                Input Manual
              </Button>
            </Stack>
          </Box>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
