import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllRole = async () => {
  const url = endpoints.VIEW_LIST_ROLE;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getAllRole
};

export default service;
