import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Stack
} from "@mui/material";
import get from "lodash/get";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import TextFieldPassword from "../components/PasswordTextField";
import useNotification from "../hooks/useNotification";
import { useAppSelector } from "../hooks/useSelector";
import auth from "../services/auth";

export default function ChangePasswordPage() {
  const navigate = useNavigate();
  const notification = useNotification();

  const { user } = useAppSelector((state) => state.global);

  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("current password is a required field"),
    newPassword: yup
      .string()
      .required("password is a required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,

        "password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("confirm password is a required field")
      .oneOf([yup.ref("newPassword")], "Password does not match!")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,

        "confirm password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
  });

  const form = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const changePassword = useMutation("change-password", auth.changePassword, {
    onSuccess: () => {
      navigate("/app/profile");

      notification.onOpen({
        message: "Your Password was succesfully updated",
        type: "success",
        position: "top",
      });
    },
    onError(error, variables, context) {
      const invalidCurrent =
        get(error, "response.data.error") === "Invalid current password !!";

      if (invalidCurrent) {
        notification.onOpen({
          message: "Sorry, Current password is invalid",
          type: "error",
          position: "top",
        });
      } else {
        notification.onOpen({
          message: "Unexpected Error!",
          type: "error",
          position: "top",
        });
      }
    },
  });

  const onSubmit = (values: any) => {
    changePassword.mutate({
      user_id: get(user, "id"),
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      retypePassword: values.confirmPassword,
    });
  };

  return (
    <MainLayout>
      <Breadcrumb
        label="Change Password"
        href="/app/profile"
        breadcrumbs={[
          { label: "Account Profile", href: "/app/profile" },
          { label: "Change Password", href: "/app/profile/change-password" },
        ]}
      />

      <Box sx={{ mt: 2 }}>
        <Grid container>
          <Grid item lg={4} xs={12}>
            <FormControl fullWidth>
              <FormLabel required>Current Password</FormLabel>
              <Controller
                name="currentPassword"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextFieldPassword
                    size="small"
                    variant="outlined"
                    {...field}
                    inputRef={ref}
                    error={Boolean(fieldState.error?.message)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel required>New Password</FormLabel>
              <Controller
                name="newPassword"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextFieldPassword
                    size="small"
                    variant="outlined"
                    {...field}
                    inputRef={ref}
                    error={Boolean(fieldState.error?.message)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormLabel required>Confirm Password</FormLabel>
              <Controller
                name="confirmPassword"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextFieldPassword
                    size="small"
                    variant="outlined"
                    {...field}
                    inputRef={ref}
                    error={Boolean(fieldState.error?.message)}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={changePassword.isLoading}
            onClick={form.handleSubmit(onSubmit)}
          >
            SAVE
          </LoadingButton>

          <Button
            variant="outlined"
            color="primary"
            component={RouterLink}
            to="/app/profile"
          >
            CANCEL
          </Button>
        </Stack>
      </Box>
    </MainLayout>
  );
}
