import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
// import fileDownload from "js-file-download";

const getAllMerchantbyEventId = async (params: any) => {
  const url = endpoints.MERCHANT;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const getAllItembyMerchantId = async (params: any) => {

    const url = endpoints.ITEM;

    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
      params: params
    });

    return response;
};

const viewMerchantDetail = async (id: any) => {
  
  if (parseInt(id)) {
    const url = endpoints.VIEW_MERCHANT(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};


const viewMerchantItemDetail = async (itemId: any) => {

  if (parseInt(itemId)) {
    const url = endpoints.VIEW_ITEM(itemId);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },      
    });
    return response;
  }
};

const createMerchant = async (params: any) => {
  const url = endpoints.CREATE_MERCHANT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },    
  });

  return response;
};

const createMerchantItem = async (params: any) => {
  const url = endpoints.CREATE_ITEM;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },    
  });

  return response;
};

const deleteMerchant = async (id: number) => {
  const url = endpoints.DELETE_MERCHANT(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteMerchantItem = async (itemId: number) => {
  const url = endpoints.DELETE_ITEM(itemId);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateMerchant = async (params: any) => {  
  const url = endpoints.VIEW_MERCHANT(params?.data.merchantId);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const resetBalanceMerchant = async (params: any) => {    
  const url = endpoints.RESET_BALANCE_MERCHANT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateMerchantItem = async (params: any) => {  
  const url = endpoints.VIEW_ITEM(params?.data.itemId);  
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getMerchantUsers = async (params: any) => {
  const url = endpoints.MERCHANT_USERS;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params
  });

  return response;
};

const service = {
  getAllMerchantbyEventId,
  viewMerchantDetail,
  createMerchant,
  deleteMerchant,
  updateMerchant,
  resetBalanceMerchant,
  viewMerchantItemDetail,
  createMerchantItem,
  deleteMerchantItem,
  updateMerchantItem,
  getAllItembyMerchantId,
  getMerchantUsers
};

export default service;
