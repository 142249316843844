import { MenuOpen } from "@mui/icons-material";
import {
  Box, Drawer, IconButton,
  List, ListItemBaseProps, ListItemButton,
  ListItemText, Stack, Typography
} from "@mui/material";
import get from "lodash/get";
import { useCallback, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useDispatch";
import { useAppSelector } from "../../hooks/useSelector";
import { actions } from "../../store/reducers/global";
import sideMenu from "./sidebarMenu";
import CookieJs from "js-cookie";
// assets
import Logo from "../../assets/images/sodbanklogo.jpg";

interface ListItemProps extends ListItemBaseProps {
  label: string;
  href: string;
  itemId: string;
}

const ListItem = (props: ListItemProps) => {
  const { label, href, itemId, ...others } = props;
  const { menuOpen } = useAppSelector((state: { global: any }) => state.global);
  const dispatch = useAppDispatch();
  const selected = menuOpen.findIndex((id: any) => id === itemId) > -1;
  const itemHandler = () => {
    dispatch(actions.openMenu(itemId));

    dispatch(actions.closeSidebar());
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === itemId);
    if (currentIndex > -1) {
      dispatch(actions.openMenu(itemId));
    }
    // eslint-disable-next-line
  }, [document.location.pathname]);

  return (
    <ListItemButton
      {...others}
      component={RouterLink}
      to={href}
      selected={selected}
      sx={{ mx: 2, borderRadius: "8px", ...others?.sx }}
      onClick={itemHandler}
    >
      <ListItemText
        primary={
          <Typography
            variant="body2"
            fontWeight={selected ? "700" : "400"}
            color={"white"}
          >
            {label}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default function DrawerSidebar() {
  const dispatch = useAppDispatch();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const { sidebar } = useAppSelector((state: any) => state.global);
  const isAdmin = get(user, "is_admin", false);

  const renderSideMenu = useCallback(
    (item: any, i: any) => {
      const isHavePermission = item.permissions.indexOf(user.role_id) >= 0;
      if (!isHavePermission && !isAdmin) {
        return <></>;
      }
      return (
        <ListItem
          key={i}
          label={item.label}
          itemId={item.id}
          href={item.href}
        />
      );
    },
    [isAdmin, user.role_id]
  );

  return (
    <Box>
      <Box
        sx={(theme) => ({
          width: "250px",
          flexShrink: 0,
          position: "fixed",
          left: 0,
          bottom: 0,
          top: "67px",
          height: "calc(100vh - 70px)",
          overflowY: "auto",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <Box
          sx={{
            backgroundColor: "#a48cbc",
            borderRightWidth: 1,
            borderRightColor: "#d3c9e0",
            borderRightStyle: "solid",
            pt: 2,
            height: "100%",
          }}
          role="presentation"
        >
          <Box>
            <List>{sideMenu.map(renderSideMenu)}</List>
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="left"
        open={sidebar}
        onClose={() => dispatch(actions.closeSidebar())}
      >
        <Box sx={{ width: 250, backgroundColor: "#a48cbc" }} role="presentation">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, py: 1 }}
          >
            <RouterLink to="/app">
              <img src={Logo} style={{ height: 24 }} alt="home"/>
            </RouterLink>

            <IconButton
              size="small"
              color="primary"
              onClick={() => dispatch(actions.closeSidebar())}
            >
              <MenuOpen />
            </IconButton>
          </Stack>

          <Box>
            <List>{sideMenu.map(renderSideMenu)}</List>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
