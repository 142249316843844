import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CookieJs from "js-cookie";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as ErrorLogo } from "../assets/images/error.svg";
import { ReactComponent as SuccessLogo } from "../assets/images/success.svg";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useAlert from "../hooks/useAlert";
import transaction from "../services/transaction";
import wristband from "../services/wristband";
import config from "../utils/config";
import { rupiah } from "../utils/currency";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F2F2F2",
  border: "2px solid #6b4a94",
  boxShadow: 24,
  p: 4,
};

export default function Refund() {
  let audio = new Audio("/beep.mp3");
  const alert = useAlert();
  const checkQrShown = () => {
    const qr = document.getElementById("html5qr-code-full-region");
    if (qr?.innerHTML === "") {
      window.location.reload();
    }
  };

  setTimeout(checkQrShown, 500);
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [data, setData] = useState({
    wristband_id: 0,
    wristband: "",
    message: "Start Scanning!",
    error: "",
    balance: 0,
    event_id: user.event_id,
    token_price: 0,
    isSuccess: false,
    isError: false,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    scanAnother();
  };

  const scanAnother = () => {
    setData({
      wristband_id: 0,
      wristband: "",
      message: "Start Scanning!",
      error: "",
      balance: 0,
      event_id: user.event_id,
      token_price: 0,
      isSuccess: false,
      isError: false,
    });
  };

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    audio.play();
    wristband
      .scanWristband({
        wristband: decodedText,
        event_id: user.event_id,
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: response.message,
          error: "",
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
        document.getElementById("html5-qrcode-button-camera-stop")?.click();
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          wristband_id: response.data?.id,
          wristband: decodedText,
          message: "Scan another wristband!",
          error: response.response.data.error,
          balance: response.data?.token,
          token_price: response.data?.event?.token_price,
        }));
      });
  };

  const refundManual = (values: { keyword: any }) => {
    onNewScanResult(values.keyword, "");
  };

  const onRefund = () => {
    transaction
      .refund({
        wristband_id: data.wristband_id,
        event_id: data.event_id,
        username: user.username,
      })
      .then((response) => {
        setData((prev: any) => ({
          ...prev,
          message: response.message,
          error: "",
          isSuccess: true,
          isError: false,
        }));
        handleOpen();
      })
      .catch((response) => {
        setData((prev: any) => ({
          ...prev,
          message: "Scan another wristband!",
          error: response.response.data.error,
          isSuccess: false,
          isError: true,
        }));
        handleOpen();
      });
    alert.onClose();
  }

  const confirmRefund = () => {
    alert.onOpen({
      title: "Confirm Refund",
      message: `Are you sure to refund wristband ${data.wristband_id}?`,
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Refund",
          onClick: () => onRefund(),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  return (
    <MainLayout>
      <Helmet>
        <title>Refund</title>
      </Helmet>

      <ProtectedPermissionPage acceptPermissions={[1, 4]}>
        <Modal open={open} onClose={handleClose}>
          <Box sx={style} textAlign="center">
            <Stack direction="column" spacing={1}>
              {data.isSuccess && (
                <Typography>
                  <SuccessLogo />
                </Typography>
              )}
              {data.isSuccess && (
                <>
                  <Typography variant="h6">
                    Wristband: {data.wristband}
                  </Typography>
                  <Typography variant="h6">Remaining Balance: 0</Typography>
                  <Typography variant="h6" marginTop="10">
                    {data.message}
                  </Typography>
                </>
              )}
              {data.isError && (
                <Typography>
                  <ErrorLogo />
                  {data.wristband_id > 0 && (
                    <>
                      <Typography variant="h6">
                        Wristband: {data.wristband}
                      </Typography>
                      <Typography variant="h6">
                        Remaining Balance: {data.balance}
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6">{data.error}</Typography>
                </Typography>
              )}
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Box>
        </Modal>

        <Typography variant="h2" textAlign="center" fontWeight="700">
          Refund
        </Typography>

        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />

        <Box
          mt={3}
          sx={{
            border: "1px solid rgba(224, 224, 224, 1)",
            padding: "10px",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              Wristband: {data.wristband !== "" ? data.wristband : "-"}
            </Grid>
            <Grid item lg={4} md={12}>
              Balance: {data.balance}
            </Grid>
            <Grid item lg={4} md={12}>
              Total: {rupiah(data.balance * data.token_price)}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ margin: "auto" }}
                disabled={data.wristband_id === 0}
                onClick={scanAnother}
              >
                Scan Another
              </Button>
              <Button
                variant="contained"
                sx={{ margin: "auto" }}
                disabled={data.balance <= 0}
                onClick={confirmRefund}
              >
                Confirm Refund
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Wristband Id here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(refundManual)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(refundManual)}
            >
              Input Manual
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
