import { createSlice } from "@reduxjs/toolkit";
import CookiesJs from "js-cookie";
import get from "lodash/get";

const initialState: any = {
  sidebar: false,
  notification: false,
  menuOpen: [],
  user: {
    userId: CookiesJs.get("ID_USER")
  },

  // Dashboard
  member_comparison_selector: "activated",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    openSidebar: (state: { sidebar: boolean }) => {
      state.sidebar = !state.sidebar;
    },
    closeSidebar: (state: { sidebar: boolean }) => {
      state.sidebar = false;
    },
    openMenu: (state, { payload }) => {
      state.menuOpen = [payload];
    },
    closeMenu: (state) => {
      state.menuOpen = [];
    },
    openNotification: (state: { notification: any }, { payload }: any) => {
      state.notification = payload;
    },
    closeNotification: (state: { notification: boolean }) => {
      state.notification = false;
    },
    signIn: (state: { user: any }, { payload }: any) => {
      state.user = payload;
    },
    signOut: (state: { user: null }) => {
      state.user = null;
    },
    getProfileSuccess: (state: { user: any }, { payload }: any) => {
      const permissions = get(payload, "roles.data", false)
        ? Object.keys(get(payload, "roles.data", false))
        : [];
      const currentPermissions = permissions
        ?.map((item) => ({
          name: item,
          value: get(payload, ["roles", "data", item]),
        }))
        ?.filter((x) => x.value)
        ?.map((x) => x.name);
      state.user = { ...payload, permissions: currentPermissions };
    },
    changeMemberComparisonSelector: (
      state: { member_comparison_selector: any },
      { payload }: any
    ) => {
      state.member_comparison_selector = payload;
    },
  },
});

export const actions = globalSlice.actions;

export default globalSlice.reducer;
