import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getAllWristbandByEventId = async (params: any) => {
  const url = endpoints.WRISTBAND;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const uploadWristband = async (params: any) => {
  const url = endpoints.UPLOAD_WRISTBAND;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const viewWristbandDetail = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.VIEW_WRISTBAND(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const flagWristband = async (params: any) => {
  const url = endpoints.FLAG_WRISTBAND;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const scanWristband = async (params: any) => {
  const url = endpoints.SCAN_WRISTBAND;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getAllWristbandByEventId,
  uploadWristband,
  viewWristbandDetail,
  flagWristband,
  scanWristband
};

export default service;
