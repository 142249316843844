import { LoadingButton } from "@mui/lab";
import {
  Box, Button, FormControl,
  FormLabel, Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import TextFieldPassword from "../components/PasswordTextField";
import useNotification from "../hooks/useNotification";
import auth from "../services/auth";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import get from "lodash/get";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const notification = useNotification();
  const location = useLocation();

  const schema = yup.object({
    password: yup
      .string()
      .required("new password is a required field")
      .matches(
        /^.*(?=.{8,})((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

        "new password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("re-type new password is a required field")
      .oneOf([yup.ref("password")], "Password does not match")
      .matches(
        /^.*(?=.{8,})((?=.*[?!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,

        "re-type new password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
  });

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control } = form;

  const query = new URLSearchParams(location.search);
  const currentToken = query.get("token");

  useEffect(() => {
    if (!currentToken) {
      notification.onOpen({
        message: "Sorry, we can't continue your request",
        type: "error",
        position: "top",
      });

      navigate("/", { replace: true });
    }
  }, [currentToken, navigate, notification]);

  const resetPassword = useMutation("reset-password", auth.resetPassword, {
    onSuccess: () => {
      form.reset();
      navigate("/", { replace: true });

      notification.onOpen({
        message: "Your Password has been reset. Please continue to Sign-in",
        type: "success",
        position: "top",
      });
    },
    onError(error, variables, context) {
      notification.onOpen({
        message: get(error, "response.data.error", "Internal Server error"),
        type: "error",
        position: "top",
      });
    },
  });

  const onSubmit = (values: any) => {
    resetPassword.mutate({
      password: values.password,
      retypePassword: values.confirmPassword,
      reset_link: currentToken,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <Box sx={{ width: 300 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h1" sx={{ mt: 1 }} color="grey.500">
            Reset Password
          </Typography>
        </Box>

        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormLabel required>Password</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextFieldPassword
                  size="small"
                  variant="outlined"
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  {...field}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormLabel required>Confirm Password</FormLabel>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextFieldPassword
                  size="small"
                  variant="outlined"
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  {...field}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            fullWidth
            loading={resetPassword.isLoading}
          >
            RESET
          </LoadingButton>

          <Button
            variant="text"
            fullWidth
            component={RouterLink}
            to="/"
            sx={{ mt: 2 }}
          >
            BACK
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
