import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const refund = async (params: any) => {
  const url = endpoints.REFUND;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const order = async (params: any) => {
  const url = endpoints.ORDER;

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getRefundLog = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.REFUND_LOG(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "refund_logs.xlsx");
      });
  }
};

const getTopupLog = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.TOPUP_LOG(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "topup_logs.xlsx");
      });
  }
};

const getOrderLog = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.ORDER_LOG(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "order_logs.xlsx");
      });
  }
};

const service = {
  refund,
  getRefundLog,
  getTopupLog,
  getOrderLog,
  order
};

export default service;
