import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useAlert from "../hooks/useAlert";
import useNotification from "../hooks/useNotification";
import service from "../services/users";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function UserList() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [options, setOptions] = useState<any>({
    keyword: "",
  });
  const notification = useNotification();
  const deleteUser = useMutation("delete-user", service.deleteUser, {
    onSuccess: () => {
      listUser.refetch();
      notification.onOpen({
        message: "User was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });

  const resetPassword = useMutation("reset-password", service.resetPassword, {
    onSuccess: () => {
      listUser.refetch();
      notification.onOpen({
        message: "New Password generated on user email",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  })
  const onDelete = (id: number) => {
    alert.onClose();
    deleteUser.mutate(id);
  };
  const alert = useAlert();
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete user?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const onReset = (id: number) => {
    alert.onClose();
    resetPassword.mutate(id);
  };

  const onClickReset = (id: number) => {
    alert.onOpen({
      title: "Confirm Reset Password?",
      message: "Are you sure want to reset user password?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Confirm",
          onClick: () => onReset(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };
  const listUser = useQuery(
    ["userlist", options],
    ({ queryKey }) => {
      return service.getUsers({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  
  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);
  return (
    <MainLayout>
      <Helmet>
        <title>User List</title>
      </Helmet>

      <Breadcrumb
        label="User List"
        breadcrumbs={[{ label: "User Management", href: "/app/userlist" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>

      <Box sx={{ mt: 2 }}>
        <Stack direction="row" spacing={1}>
          <ProtectedPermissionPage acceptPermissions={[1]}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/app/users/create-user"
            >
              ADD USER
            </Button>
          </ProtectedPermissionPage>
        </Stack>
      </Box>

      <Box sx={{ mt: 2 }}>
        <DataGrid
          autoHeight
          loading={listUser.isLoading}
          getRowId={(record) => get(record, "id")}
          rows={get(listUser, "data.data", [])}
          rowCount={get(listUser, "data.data", []).length}
          page={options.page_number}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          disableSelectionOnClick
          onPageChange={(newPage) => {
            setOptions((prev: any) => ({ ...prev, page_number: newPage }));
          }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            NoRowsOverlay: CustomNoRowOverlay,
          }}
          columns={[
            {
              field: "username",
              headerName: "Username",
              flex: 1,
              filterable: false,
              maxWidth: 150,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "event_name",
              headerName: "Event",
              flex: 1,
              filterable: false,
              maxWidth: 250,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "role_name",
              headerName: "Role",
              flex: 1,
              filterable: false,
              maxWidth: 100,
              renderCell: (params) => {
                return (
                  <Stack>
                    <Typography>{params.value}</Typography>
                  </Stack>
                );
              },
            },
            {
              field: "actions",
              headerName: "Action",
              sortable: false,
              filterable: false,
              minWidth: 350,
              renderCell: (params) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={RouterLink}
                      to={`/app/users/edit/${get(params, "row.id")}`}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => onClickReset(params.row.id)}
                    >
                      Reset Password
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => onClickDelete(params.row.id)}
                    >
                      Delete
                    </Button>
                  </Stack>
                );
              },
            },
          ]}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
          }}
        />
      </Box>
    </MainLayout>
  );
}
