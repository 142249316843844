import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { DataGrid } from "@mui/x-data-grid";
import CookieJs from "js-cookie";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useAlert from "../hooks/useAlert";
import useNotification from "../hooks/useNotification";
import merchant from "../services/merchant";
import config from "../utils/config";
import queryDefault from "../utils/queryDefault";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function MerchantDetail() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const location = useLocation();
  const path = location.pathname.split("/");
  const merchant_id = path[3];
  const [pageSize, setPageSize] = useState<number>(10);
  const notification = useNotification();
  const alert = useAlert();
  const [options, setOptions] = useState<any>({
    keyword: "",
    merchant_id: merchant_id,
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    total_order: 0,
    total_items_sold: 0,
    balance: 0,
  });
  const [quantityReport, setQuantityReport] = useState([
    {
      name: "",
      order_count: 0,
    },
  ]);
  const [totalOrderReport, setTotalOrderReport] = useState([
    {
      grouped_hours: "",
      total_order: 0,
    },
  ]);

  const listItem = useQuery(
    ["item", options],
    ({ queryKey }) => {
      return merchant.getAllItembyMerchantId({ ...queryKey[1] });
    },
    {
      ...queryDefault,
    }
  );

  const chartSetting = {
    height: 300,
  };

  useEffect(() => {
    merchant
      .viewMerchantDetail(merchant_id)
      .then((response: any) => {
        setData({
          name: response.data?.name || "",
          email: response.data?.email || "-",
          total_order: response.data?.total_order || 0,
          total_items_sold: response.data?.total_items_sold || 0,
          balance: response.data?.balance || 0,
        });
      })
      .catch((err) => console.log(err));

    merchant
      .getAllItembyMerchantId({ merchant_id, keyword: "" })
      .then((response: any) => {
        const rows = response.data?.rows || [];
        const formattedData = rows.map((item: any) => ({
          name: item.name,
          order_count: parseInt(item.order_count, 10) || 0,
        }));
        setQuantityReport(formattedData || []);

        const orderGraphRows = response.data?.orderGraph?.rows || [];
        const formattedData2 = orderGraphRows.map((item: any) => {
          const refundDate = new Date(item.grouped_hours);

          return {
            grouped_hours: `${refundDate.getDate()}-${refundDate.getHours()}`,
            total_order: parseInt(item.total_order, 10) || 0,
          };
        });
        setTotalOrderReport(formattedData2 || []);
      })
      .catch((err) => console.log(err));
  }, [merchant_id]);

  const deleteMerchantItem = useMutation(
    "delete-merchant",
    merchant.deleteMerchantItem,
    {
      onSuccess: () => {
        listItem.refetch();
        notification.onOpen({
          message: "Item was successfully deleted!",
          type: "success",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );
  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteMerchantItem.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete merchant?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>MERCHANT DETAIL</title>
      </Helmet>

      <Breadcrumb
        label={`${data.name}`}
        breadcrumbs={[{ label: "Merchant", href: "/app/merchant" }]}
        href={user.role_id === 1 ? "/app/merchant" : ""}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 3]}>
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              component={RouterLink}
              to={`/app/merchant/${merchant_id}/item/create-item`}
            >
              ADD ITEM
            </Button>

            <Button
              variant="contained"
              component={RouterLink}
              to={`/app/merchant/${merchant_id}/order`}
            >
              ADD ORDER
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.email}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Item Sold
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_items_sold}
                </Typography>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Order
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_order}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Balance
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.balance}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <DataGrid
            style={{ marginTop: "20px" }}
            autoHeight
            loading={listItem.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listItem, "data.data.rows", [])}
            rowCount={get(listItem, "data.data.rows", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "price",
                headerName: "Price",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "order_count",
                headerName: "Item Sold",
                flex: 1,
                filterable: false,
                minWidth: 150,
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/app/merchant/${get(
                          params,
                          "row.merchant_id"
                        )}/edit/item/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />

          {quantityReport.length > 0 && (
            <>
              <Grid marginTop="10px"></Grid>
              <Typography variant="h3">Quantity of Items Sold</Typography>
              <BarChart
                dataset={quantityReport}
                xAxis={[{ scaleType: "band", dataKey: "name", label: "Item" }]}
                series={[
                  { dataKey: "order_count", type: "bar", label: "Sold" },
                ]}
                tooltip={{ trigger: "axis" }}
                {...chartSetting}
              />
            </>
          )}
          {totalOrderReport.length > 0 && (
            <>
              <Grid marginTop="10px"></Grid>
              <Typography variant="h3">Total Order Over Time</Typography>
              <BarChart
                dataset={totalOrderReport}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "grouped_hours",
                    label: "Date-Hour",
                  },
                ]}
                series={[{ dataKey: "total_order", label: "Total Order" }]}
                tooltip={{ trigger: "axis" }}
                {...chartSetting}
              />
            </>
          )}
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
