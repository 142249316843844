import { Box, CircularProgress, Typography } from "@mui/material";
import MainLayout from "../components/MainLayout";

export default function LoadingPage() {
    return (
        <MainLayout>
            <Box sx={{ minHeight: 'calc(100vh - 150px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={48} />

                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Wait for moment
                    </Typography>
                </Box>
            </Box>
        </MainLayout>
    )
}