import { createContext, useEffect, useMemo, useState } from "react";
import Notification from "../components/Notification";

interface NotificationProps {
  message: string;
  type: any;
  position?: "top" | "bottom";
  actions?: any;
}

export type NotificationContextType = {
  onOpen: (options: NotificationProps) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  onOpen: () => {},
});

export const NotificationProvider = ({ children }: any) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "info",
    position: "bottom",
    actions: null,
  });

  const onOpen = (options: NotificationProps) => {
    setNotification({
      open: true,
      message: options.message,
      type: options.type,
      position: options.position || "bottom",
      actions: options.actions,
    });
  };

  const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setNotification({
      open: false,
      message: "",
      type: "info",
      position: "bottom",
      actions: null,
    });
  };

  const context = useMemo(() => ({ onOpen }), [onOpen]);

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }, [notification])

  return (
    <NotificationContext.Provider value={context}>
      {children}

      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.type}
        position={notification.position}
        actions={notification.actions}
        onClose={onClose}
      />
    </NotificationContext.Provider>
  );
};
