import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import CookieJs from "js-cookie";
import { get } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import useNotification from "../hooks/useNotification";
import merchant from "../services/merchant";
import queryDefault from "../utils/queryDefault";

export default function MerchantEditPage() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const user = JSON.parse(CookieJs.get("USER") || "");
  const schema = yup.object().shape({
    name: yup.string().required("merchant name is a required field"),
    user: yup.string().required("Username required"),
    email: yup.string().email("Must be a valid email").optional(),
  });

  const currentMerchant = useQuery("view-merchant", () => merchant.viewMerchantDetail(id));
  const merchantUsers = useQuery("merchant-users", () => merchant.getMerchantUsers({
    event_id: user.event_id
  }));
  const form = useForm({
    defaultValues: {
      name: "",
      user: "",
      email: "",
      event_id: user.event_id,
      username: user.username,
      user_id: 0
    },
    resolver: yupResolver(schema),
  });

  const createMerchant = useMutation(
    "create_merchant",
    merchant.createMerchant,
    {
      onSuccess: () => {
        navigate("/app/merchant/");

        notification.onOpen({
          message: "Merchant was successfully created!",
          type: "success",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const updateMerchant = useMutation(
    "update-merchant",
    merchant.updateMerchant,
    {
      onSuccess: () => {
        navigate("/app/merchant/");

        notification.onOpen({
          message: "Merchant was successfully created!",
          type: "success",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const onSubmit = (values: any) => {
    if (id) {
      updateMerchant.mutate({
        data: {
          ...values,
          merchantId: id,
        },
      });
    } else {
      createMerchant.mutate({
        data: values,
      });
    }
  };

  useEffect(() => {
    if (id) {
      form.setValue("name", get(currentMerchant, "data.data.name"));
      form.setValue("email", get(currentMerchant, "data.data.email"));
      form.setValue("event_id", user.event_id);
      form.setValue("user", get(currentMerchant, "data.data.user"));
      form.setValue("user_id", get(currentMerchant, "data.data.user_id"));
    }
  }, [currentMerchant, form, id, user]);

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Merchant" : "Create Merchant"}
        breadcrumbs={[
          { label: "Merchant", href: "/app/merchant" },
          {
            label: id ? "Edit Merchant" : "Create Merchant",
            href: "/app/merchants/create-merchant",
          },
        ]}
        href={"/app/merchant"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        {!currentMerchant.isLoading && !merchantUsers.isLoading ? (
          <Box sx={{ mt: 2 }}>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth>
                  <FormLabel required>Username</FormLabel>
                  <Controller
                    name="user"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                        disabled={!!id}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel required>Merchant Name</FormLabel>
                  <Controller
                    name="name"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <FormLabel sx={{ '& .MuiFormLabel-asterisk': { display: 'none' } }} required>Email</FormLabel>
                  <Controller
                    name="email"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="email"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2, display: "none" }}>
                  <FormLabel required>Event Id</FormLabel>
                  <Controller
                    name="event_id"
                    control={form.control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        inputRef={ref}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                        size="small"
                      />
                    )}
                  />
                </FormControl>

                {id ? (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <FormLabel required>User</FormLabel>
                    <Controller
                      name="user_id"
                      control={form.control}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <Box>
                          <Select
                            {...field}
                            size="small"
                            error={Boolean(fieldState.error?.message)}
                            fullWidth
                          >
                            {get(merchantUsers, ["data", "data"], []).map(
                              (item: any, i: any) => {
                                console.log(item);
                                return (
                                <MenuItem value={item?.id} key={item?.id}>
                                  {item?.username}
                                </MenuItem>
                              )}
                            )}
                          </Select>

                          <FormHelperText error={Boolean(fieldState.error?.message)}>
                            {fieldState.error?.message}
                          </FormHelperText>
                        </Box>
                      )}
                    />
                  </FormControl>
                ) : <></>}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              loading={createMerchant.isLoading}
            >
              SAVE
            </LoadingButton>

            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
