import { Box, Grid, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import service from "../services/wristband";

function noRowsOverlay() {
  return (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );
}

const WristbandDetail = (): JSX.Element => {
  const params = useParams();
  const [data, setData] = useState({
    id: '',
    wristband: '',
    token: 0,
    is_flagged: false,
    transactions: [],
  });
  if (params.id !== data.id) {
    service.viewWristbandDetail(params.id).then((response) => {
      setData({
        ...response.data,
        id: params.id
      });
    }).catch((err) => {
      console.log(err);
    });
  }
  return (
    <MainLayout>
      <Helmet>
        <title>Wristband</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Wristband", href: "/app/wristband" }]}
        label={`${data.wristband}`}
        href="/app/wristband"
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Wristband
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.wristband}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Token
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.token}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Stack>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    Is Flagged?
                  </Typography>
                  {data.is_flagged ?
                    <Typography
                      variant="body1"
                      color="green"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      YES
                    </Typography>
                    : <Typography
                      variant="body1"
                      color="red"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      NO
                    </Typography>
                  }
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <DataGrid
            autoHeight
            getRowId={(record) => get(record, "id")}
            rows={data.transactions}
            rowCount={data.transactions?.length}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: noRowsOverlay,
            }}
            columns={[
              {
                field: "created_by",
                headerName: "PIC",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
              },
              {
                field: "total_amount",
                headerName: "Amount",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 100,
              },
              {
                field: "type",
                headerName: "Type",
                flex: 1,
                sortable: false,
                filterable: false,
                minWidth: 50,
                renderCell: (params) => 
                  <Typography
                    variant="body2"
                    color={params.value === 'Topup' ? 'green' : params.value === "Refund" ? 'red' : 'blue'}
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {params.value}
                  </Typography>
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
};

export default WristbandDetail;