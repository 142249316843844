import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";

const getVoucherByEventId = async (params: any) => {
  const url = endpoints.VOUCHER + "";

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params,
  });

  return response;
};

const getVoucherById = async (id: any) => {
  const url = endpoints.VOUCHER + `/${id}`;
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const createVoucher = async (params: any) => {
  const url = endpoints.VOUCHER;

  const { data: response } = await axios.post(url, params?.data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getVoucherUsageByCode = async (params: any) => {
  const url = endpoints.VOUCHER + "/usage";

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getAvailableVoucher = async (params: any) => {
  const url = endpoints.VOUCHER + "/available";

  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteVoucher = async (id: any) => {
  const url = endpoints.VOUCHER + `/${id}`;

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  createVoucher,
  deleteVoucher,
  getVoucherByEventId,
  getVoucherById,
  getVoucherUsageByCode,
  getAvailableVoucher
};

export default service;
