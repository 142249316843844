import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import get from "lodash/get";
import isObject from 'lodash/isObject';
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouterLink } from 'react-router-dom';
import * as yup from "yup";
import useNotification from "../hooks/useNotification";
import auth from "../services/auth";

export default function ForgotPage() {
  const schema = yup.object({
    email: yup.string().email().required(),
  });

  const form = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const notification = useNotification();

  const { control, handleSubmit } = form;

  const forgotPassword = useMutation("forgot-password", auth.forgotPassword, {
    onSuccess: () => {
      form.reset();
      notification.onOpen({
        message: "Please check your email to reset your Password",
        type: "success",
      });
    },
    onError(error, variables, context) {
      if (isObject(get(error, "response.data.error", {}))) {
        notification.onOpen({
          message: "Oops! Something went wrong!",
          type: "error",
          position: "top",
        });
      } else {
        notification.onOpen({
          message: get(error, "response.data.error", "Oops! Something went wrong!"),
          type: "error",
          position: "top",
        });
      }
    },
  });

  const onSubmit = (values: any) => {
    forgotPassword.mutate(values);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <Box sx={{ width: 300 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h1" sx={{ mt: 1 }} color="grey.500">
            Forgot Password
          </Typography>
        </Box>

        <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <FormLabel required>Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  {...field}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            fullWidth
            loading={forgotPassword.isLoading}
          >
            SUBMIT
          </LoadingButton>

          <LoadingButton
            variant="text"
            color="primary"
            sx={{ mt: 2 }}
            fullWidth
            component={RouterLink}
            to="/"
          >
            BACK
          </LoadingButton>

          {/* <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="h6">
              Don’t receive a code yet?{" "}
              <Link fontWeight="700" underline="none" component="button">
                Resend
              </Link>
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
