import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import CookieJs from "js-cookie";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import get from "lodash/get";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../components/ProtectedPermissionView";
import useNotification from "../hooks/useNotification";
import event from "../services/event";
import merchant from "../services/merchant";
import transaction from "../services/transaction";
import wristband from "../services/wristband";
import { rupiah } from "../utils/currency";
import queryDefault from "../utils/queryDefault";
import { Controller, useForm } from "react-hook-form";
import { Close, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

export default function EventDetail() {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const location = useLocation();
  const path = location.pathname.split("/");
  const event_id = path.pop() || "";
  const [options, setOptions] = useState<any>({
    keyword: "",
    event_id: event_id,
  });
  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });
  const listMerchant = useQuery(
    ["merchants", options],
    ({ queryKey }) => {
      return merchant.getAllMerchantbyEventId({
        ...queryKey[1],
        event_id: event_id,
      });
    },
    {
      ...queryDefault,
    }
  );
  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);
  const [topupReport, setTopupReport] = useState([{ username: "", count: 0 }]);
  const [refundReport, setRefundReport] = useState([
    { username: "", count: 0 },
  ]);
  const [orderReport, setOrderReport] = useState([{ username: "", count: 0 }]);
  const [topupStats, setTopupStats] = useState([
    { grouped_hours: "", count: 0 },
  ]);
  const [refundStats, setRefundStats] = useState([
    { grouped_hours: "", count: 0 },
  ]);
  const [orderStats, setOrderStats] = useState([
    { grouped_hours: "", count: 0 },
  ]);
  const chartSetting = {
    height: 300,
  };
  const [data, setData] = useState({
    id: "",
    name: "",
    token_price: 0,
    total_topup: 0,
    total_refund: 0,
    file_path: "",
    created_at: "",
    wristband_total: 0,
    topup_commission: 0,
    transaction_commission: 0,
    total_balance: 0,
  });

  const [netBalance, setNetBalance] = useState(0);

  useEffect(() => {
    event.viewEventDetail(event_id).then((response: any) => {
      setData({
        id: event_id,
        name: response.data?.name,
        token_price: response.data?.token_price,
        total_topup: response.data?.total_topup,
        total_refund: response.data?.total_refund,
        file_path: response.data?.file_path,
        created_at: response.data?.created_at,
        wristband_total: response.data?.wristband_total,
        topup_commission: response.data?.topup_commission,
        transaction_commission: response.data?.transaction_commission,
        total_balance: response.data?.merchant.total_balance,
      });
      if (response.data.topup_stats?.length > 0) {
        for (const i of response.data.topup_stats) {
          var topupDate = new Date(i.grouped_hours);
          i.grouped_hours = `${topupDate.getDate()}-${topupDate.getHours()}`;
          i.count = Number(i.count);
        }
        setTopupStats(response.data.topup_stats);
      }
      if (response.data?.refund_stats?.length > 0) {
        for (const i of response.data.refund_stats) {
          var refundDate = new Date(i.grouped_hours);
          i.grouped_hours = `${refundDate.getDate()}-${refundDate.getHours()}`;
          i.count = Number(i.count);
        }
        setRefundStats(response.data.refund_stats);
      }
      if (response.data?.order_stats?.length > 0) {
        for (const i of response.data.order_stats) {
          var orderDate = new Date(i.grouped_hours);
          i.grouped_hours = `${orderDate.getDate()}-${orderDate.getHours()}`;
          i.count = Number(i.count);
        }
        setOrderStats(response.data.order_stats);
      }
      if (response.data.topup_report?.length > 0) {
        for (const i of response.data.topup_report) {
          i.count = Number(i.count);
        }
        setTopupReport(response.data?.topup_report);
      }
      if (response.data.refund_report?.length > 0) {
        for (const i of response.data.refund_report) {
          i.count = Number(i.count);
        }
        setRefundReport(response.data?.refund_report);
      }
      if (response.data.order_report?.length > 0) {
        for (const i of response.data.order_report) {
          i.count = Number(i.count);
        }
        setOrderReport(response.data?.order_report);
      }
    });
  }, [event_id]);

  useEffect(() => {
    setNetBalance(data.total_topup - data.total_refund - data.total_balance);
  }, [data]);

  const notification = useNotification();
  const uploadWristband = useMutation(
    "upload_wristband",
    wristband.uploadWristband,
    {
      onSuccess: () => {
        notification.onOpen({
          message: "Wristband was successfully uploaded!",
          type: "success",
          position: "top",
        });
        window.location.reload();
      },
      onError: (error: any) => {
        notification.onOpen({
          message: error?.response?.data?.message,
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("event_id", event_id);
    event.target.files && formData.append("file", event.target.files[0]);
    formData.append("username", user.username);
    uploadWristband.mutate(formData);
  };

  const download = useMutation("download", event.downloadFile);
  const handleDownload = () => download.mutate(data.file_path);
  const CustomNoRowOverlay = () => (
    <Box sx={{ pt: 5 }}>
      <Typography variant="body1" textAlign="center" fontWeight="600">
        Sorry, data is not found
      </Typography>
    </Box>
  );

  return (
    <MainLayout>
      <Helmet>
        <title>Event</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Event", href: "/app/event" }]}
        label={data.name}
        href={"/app/event"}
      />

      <ProtectedPermissionPage acceptPermissions={[1]}>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Created At
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {moment(data.created_at).format("D/MM/YYYY HH:mm")}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Token Price
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {rupiah(data.token_price)}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Topup
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_topup}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Refund
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_refund}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Balance All Merchant
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.total_balance}
                </Typography>
              </Stack>
            </Grid>

            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Topup Commission
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {rupiah(data.topup_commission)}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Transaction Commission
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.transaction_commission} %
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Total Wristband
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.wristband_total}
                </Typography>
              </Stack>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Net Balance
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {netBalance}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => transaction.getTopupLog(data.id)}
                >
                  Topup Logs
                </Button>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => transaction.getRefundLog(data.id)}
                >
                  Refund Logs
                </Button>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => transaction.getOrderLog(data.id)}
                >
                  Order Logs
                </Button>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (window.confirm("Are you sure to reset event?")) {
                      event
                        .resetEvent({
                          id: data.id,
                          username: user.username,
                        })
                        .then(() => {
                          notification.onOpen({
                            message: "Event successfully reset!",
                            type: "success",
                            position: "top",
                          });
                          window.location.reload();
                        })
                        .catch((error: any) => {
                          notification.onOpen({
                            message: error?.response?.data?.error,
                            type: "error",
                            position: "top",
                          });
                        });
                    }
                  }}
                >
                  Reset Event
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <ProtectedPermissionView acceptPermissions={[1]}>
            <Grid sx={{ mt: 2 }}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" component="label">
                  Upload Wristband
                  <input onChange={handleFileChange} type="file" hidden />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/UploadTemplateWristband.xlsx`}
                  target="_blank"
                >
                  Download Template
                </Button>
                {data.file_path ? (
                  <Stack sx={{ mt: 2 }}>
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      onClick={() => handleDownload()}
                      loading={download.isLoading}
                    >
                      Download Latest Excel
                    </LoadingButton>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </ProtectedPermissionView>
          {/* <Category id={event_id} /> */}

          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Topup Graph</Typography>
          <BarChart
            dataset={topupReport}
            xAxis={[{ scaleType: "band", dataKey: "username", label: "User" }]}
            series={[{ dataKey: "count", type: "bar", label: "Total" }]}
            tooltip={{ trigger: "axis" }}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Refund Graph</Typography>
          <BarChart
            dataset={refundReport}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "username",
                label: "User",
              },
            ]}
            series={[{ dataKey: "count", stack: "1", label: "Total" }]}
            tooltip={{ trigger: "item" }}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Order Graph</Typography>
          <BarChart
            dataset={orderReport}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "username",
                label: "User",
              },
            ]}
            series={[{ dataKey: "count", type: "bar", label: "Total" }]}
            tooltip={{ trigger: "axis" }}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Topup Statistics</Typography>
          <BarChart
            dataset={topupStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_hours",
                label: "Date-Hour",
              },
            ]}
            series={[{ dataKey: "count", label: "Total" }]}
            tooltip={{ trigger: "axis" }}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Refund Statistics</Typography>
          <BarChart
            dataset={refundStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_hours",
                label: "Date-Hour",
              },
            ]}
            series={[{ dataKey: "count", label: "Total" }]}
            tooltip={{ trigger: "axis" }}
            {...chartSetting}
          />
          <Grid marginTop="10px"></Grid>
          <Typography variant="h3">Order Statistics</Typography>
          <BarChart
            dataset={orderStats}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "grouped_hours",
                label: "Date-Hour",
              },
            ]}
            series={[{ dataKey: "count", label: "Total" }]}
            tooltip={{ trigger: "axis" }}
            {...chartSetting}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
